import PropTypes from "prop-types";
import React, { useContext, useEffect, useState } from 'react'
import parse from "html-react-parser"
import { connect, useDispatch, useSelector } from 'react-redux'
import { useToasts } from 'react-toast-notifications'
import { animateScroll } from 'react-scroll'
import {
  getDiscountPrice,
  getMoqNote,
  getPersColor,
  getPersFont,
  getPersLocation,
  getPersNote,
  getShowMoqNote,
  getShowProductPrice,
  numberWithCommas2,
  roundedPrice,
  WithCommas
} from '../../helpers/product'
import Swiper from 'react-id-swiper'
import PlaceHolder from '../../assets/img/placeholder-image.png'
import * as productsAPIUtil from '../../util/products/productsAPIUtil'
import * as advanceAPIUtil from '../../util/advance/advanceAPIUtil'
import Copyrowicon from '../../assets/img/copyrow.png'
import { Button, IconButton, Table, useMediaQuery } from '@material-ui/core'
import Select from 'react-select'
import AddIcon from '@material-ui/icons/Add'
import DeleteIcon from '@material-ui/icons/Delete'
import { useHistory } from 'react-router-dom'
import { addItemsRowsTocart } from '../../redux/actions/cartActions'
import { AuthContext } from '../../common/Auth'
import AddDecorationportal from '../../components/product/sub-components/AddDecorationportalCondensed'
import DecorationSelectbox from '../../components/product/sub-components/DecorationSelectboxCondensed'
import {
  fetchArtworksById,
  fetchDecorationLocations,
  fetchProductStock,
  receiveDecorationLocations,
} from '../../redux/actions/productActions'
import AddnewArtDialogue from '../../components/artwork/AddnewArtDialogue'
import useElementSize from '../../util/custom-hooks/useElementSize'
import { LightgalleryItem, LightgalleryProvider } from 'react-lightgallery'
import { productImageExt, productImageUrl } from '../../util/helper'
import { X_TENANT_ID } from "../../common/ActionUrl";
import Axios from "axios";
import { USERTYPES } from '../../constant/userType'
import SharedModal from "../modal/SharedModal";
import { useThemeSettings } from "../../layouts/state/useThemeSettings";
import { useThemeSettingsData } from "../../layouts/state/useThemeSettingsData";
import { useProductConfigState } from "../../pages/shop-product/state/useProductConfigState";
import { useProductDetailState } from "../../pages/shop-product/state/useProductDetailState";
import Loader from "../../common/Loader";
import { useProductPriceToShow } from "../../components/product/state/useProductPriceToShow";
import PriceTable from "./productPrice/PriceTable";
import { useProductArtworkState } from "../../components/product/state/useProductArtworkState";
import { addOnChargesPersBody } from "../../common/AdvanceBodies";
import { useProductPriceState } from "../../components/product/state/useProductPriceState";
import { useProductDecoCharge } from "../../components/product/state/useProductDecoCharge";
import {_useToasts} from '../../common/_useToasts'
let uniqIdcounter = 1
const PortalImageCondensed = ({
                                product,
                                currency,
                                cartItems,
                                decorationLocations,
                                wishlistItems,
                                artworkList,
                                fetchArtworksById,
                                addItemsRowsTocart,
                                fetchDecorationLocations,
                                compareItems,
                                artworkListById,
  isModel = false,
  fetchProductStock,
                              }) => {
  const {_addToast} = _useToasts();
  const {
    settingData,
    themeSettingsData,
    storeDetails,
    isDirectToCart,
    showAddToCart,
    showAddToCartOnProd,
    userGroupDiscount,
    decoTypeList,
    categoriesList,
    userType,
    ipV4,
    user,
    pointValue, usePointsAsCurrency
  } = useContext(AuthContext)
  const history = useHistory()

  const [imageScroll, setImageScroll] = useState(0)

  const isSmall = useMediaQuery('(max-width: 640px)')
  const isMedium = useMediaQuery('(max-width: 1007px)')
  const [productInventory, setProductInventory] = useState(null)
  const [productDstInventory, setProductDstInventory] = useState(null)
  const [personalizationAdded, setPersonalizationAdded] = useState(false)
  const [statchange, updatestate] = useState(false)
  const [selectedProductSize, setSelectedProductSize] = useState(null)
  const [processSingle, setProcessSingle] = useState(false)
  const [showAddnewart, setAddnewart] = useState(false)
  const [showAddnewartId, setAddnewartId] = useState(0)
  const [loading, setLoading] = useState(false)
  const [selectedImage, setSelectedImage] = useState('')
  const [IsSelectedImageChng, setIsSelectedImageChng] = useState(0)
  const [tempSelectedColor, setTempSelectedColor] = useState('')
  const [tempItemRowArray, setTempItemRowArray] = useState('')
  const [imageOnPageLoad, setImageOnPageLoad] = useState('')
  const [primaryImage, setPrimaryImage] = useState(null)
  const [selectedProductColor, setSelectedProductColor] = useState(null)
  const [selectedDecoration, setSelectedDecoration] = useState(null)
  const [autoSelectedLocation, setAutoSelectedLocation] = useState('')
  const [selectedLocatons, setLocationSelected] = useState([])
  const [addRowQty, setaddRowQty] = useState(null)
  const [hideLocationName, setHideLocationName] = useState(null)
  const [personalizationCost, setPersonalizationCost] = useState({
    matchOrderQty: 0,
    price: 0,
    setupPrice: 0,
    chargeId: ''
  })
  const [selectedArtworkID, setselectedArtworkID] = useState([])
  const [gallerySwiper, getGallerySwiper] = useState(null)
  const [thumbnailSwiper, getThumbnailSwiper] = useState(null)
  const [currentqid, setCurrentqid] = useState(null)
  const [currentActiveId, setCurrentActiveId] = useState(null)
  const [selectedProductLocation, setSelectedProductLocation] = useState('')
  const [selectedProductArtwork, setSelectedProductArtwork] = useState('')
  const [selectedProductDecorations, setSelectedProductDecorations] = useState(
      [],
  )
  const [isNewArtWorkAddedMain, setIsNewArtWorkAddedMain] = useState(0);
  const [imgW, imgH] = useElementSize((isModel ? '.modalportal .swiper-slide-active .containerimg img': '.swiper-slide-active .containerimg img'))
  const [imageW, setImageW] = useState(imgW)
  const [imageH, setImageH] = useState(imgH)
  const [toggleDivsArray, setToggleDivs] = useState([])
  const [toggleValue, setToggleValue] = useState(false)
  const [catalogueCustomRows, setCatalogueCustomRows] = useState([])
  const [notCallDecoHtml, setNotCallDecoHtml] = useState(false)
  const [showData, setShowData] = useState('yes')
  const [cartItem, setCartItem] = useState(0)
  const [prodId, setProdId] = useState(null)
  const [productId, setProductId] = useState(null)
  const [showColor, setShowColor] = useState(true)
  const [showSize, setShowSize] = useState(true)
  const [isMoq, setIsMoq] = useState(false)
  const [isSkuMoq, setIsSkuMoq] = useState(false)
  const [isRestrictQty, setIsRestrictQty] = useState(false)
  const [isPriceBreak, setIsPriceBreak] = useState(false)
  const [partPrice, setPartPrice] = useState([])
  const [minQty, setMinQty] = useState('1')
  const [minAllQty, setMinAllQty] = useState([])
  const [isMinskuQty, setIsMinskuQty] = useState(false)
  const [selectedLocation, setSelectedLocation] = useState('')
  const [selectedLocationIndex, setSelectedLocationIndex] = useState(0)
  const [remainLcoationList, setRemainLcoationList] = useState([])
  const [swipeimageFlag, setSwipeimageFlag] = useState(true)
  const [selectedLocationName, setSelectedLocationName] = useState('')
  const [isDisableProductClone, setIsdisableProductClone] = useState(true)
  const [isReplace, setIsReplace] = useState(true)
  const [searchTextArtwork, setTextSearchArtwork] = useState('')
  const [isSearchArtwork, setIsSearchArtwork] = useState(false)
  const [isTrue, setIsTrue] = useState(false)
  const [allowOneDec, setallowOneDec] = useState("0")
  const [RequiredDecorationForCheckOutPro, setRequiredDecorationForCheckOutPro] = useState(false)

  const [customDecorationFee, setCustomDecorationFee] = useState(false)
  const [customDecorationPerUnitPrice, setCustomDecorationPerUnitPrice] = useState(null)
  const [customDecorationSetupFee, setCustomDecorationSetupFee] = useState(null)
  const [personalizationPerUnitPrice, setPersonalizationPerUnitPrice] = useState(null)
  const [personalizationSetupFee, setPersonalizationSetupFee] = useState(null)

  const [storeArtwork, setStoreArtwork] = useState([])
  const [locationId, setLocationId] = useState('');
  const [IsAllowOutOfStockOrder, setIsAllowOutOfStockOrder] = useState(false)
  const disIndProductPrice = getShowProductPrice(product)
  const [sizesStock, setSizesStock] = useState(true);
  const [showAddVariation, setShowAddVariation] = useState(false);
  const [artworksToRead, setArtworksToRead] = useState([]);
  const [moqNote, setMoqNote] = useState('Minimum order quantity for this item is [MOQ]');
  const [showMoqNote, setShowMoqNote] = useState(false);
  const [statChangeTemp, updateStateTemp] = useState(0)
  const [addRowQtyFirst, setaddRowQtyFirst] = useState(0)
  const [showDecoPopup, setShowDecoPopup] = useState(false);
  const [indexPopup, setIndexPopup] = useState([])
  const [mainArrayKeyPopup, setMainArrayKeyPopup] = useState([])
  const [itemsRowsPopup, setItemsRowsPopup] = useState([])
  const [popupText, setPopupText] = useState('');
  const [isNewArtWorkAdded, setIsNewArtWorkAdded] = useState(0);
  const [artworkLocations, setArtworkLocations] = useState([]);
  const [loadingArtworks, setLoadingartworks] = useState(false);
  const [showDecorationCostInCart, setShowDecorationCostInCart] = useState(false);
  const [showSetupChargeInCart, setShowSetupChargeInCart] = useState(false);
  const [isProductInventoryEnabled, setIsProductInventoryEnabled] = useState(true)
  const [isAdded, setIsAdded] = useState(false)
  const [preventAutoAttach, setPreventAutoAttach] = useState(false)
  const [productSettingShowDescription, setProductSettingShowDescription] = useState(true)
  const [decorationListOrder, setDecorationListOrder] = useState(false);
  const [showPersonalizationCostInCart, setShowPersonalizationCostInCart] = useState(false);
  const [personalizationDropDown, setPersonalizationDropDown] = useState(false)
  const [personalizationRequired, setPersonalizationRequired] = useState(false)
  const [requiredLinesPersonalization, setRequiredLinesPersonalization] = useState('')
  const [isDrpDeco, setIsDrpDeco] = useState(false)
  const [dropDownValues, setDropDownValues] = useState([])
  const [allowCustomDecoration, setAllowCustomDecoration] = useState(false);
  const [colorLabel, setColorLabel] = useState('Color')
  const [sizeLabel, setSizeLabel] = useState('Size')
  const [decimalsToShow, setDecimalsToShow] = useState(2);
  const [isStockFor, setIsStockFor] = useState(null)
  const [defaultSizeForAll, setDefaultSizeForAll] = useState([])
  const [fetchedInvProductId, setFetchedInvProductId] = useState('')
  const [appliedFirstDeco, setAppliedFirstDeco] = useState(false)
  const [isFirstTime, setIsFirstTime] = useState(true)
  const [hideDecorationName, setHideDecorationName] = useState(null)
  const [priceTables, setPriceTable] = useState([])
  const [partIds, setPartIds] = useState([]);
  const dispatch = useDispatch()

  const {
    priceDisplay,
    itempriceDisplay,
    itemNumber,
    DefaultProductBackgroundColor,
    artworkSetting,
    sortSizeSetting
  } = useThemeSettings({ settingData })

  const {
    inventorySettings,
    isAllowoutofStockOrder,
    isStock,
    rowText,
    productSettings,
  } = useThemeSettingsData({ themeSettingsData });

  const  {
    getPointsFromValue,
    getPointsValue
  } = useProductPriceToShow({ productSettings, product })

  const {
    showSellPrice,
    showSell,
    showPrice,
    offerPrice,
    discountType
  } = useProductPriceState({product, currency, userGroupDiscount, itempriceDisplay, priceDisplay, user})

  const {
    productCategoriesName,
  } = useProductDetailState({loadedProduct: product})


  const colorStyles = {
    container: (styles) => ({...styles, textAlign: 'left', 
    }),
    input: (styles) => ({...styles,  position: "absolute",
      top: "0",
      width: "100%",
      height: "40px",
      left: "0",
      padding: "0",
      margin: "0",
      display: "block",
      '& > input' : {
        position: "absolute",
        top: "-4px",
        height: "40px",
        left: "0",
        paddingLeft: "10px !important",
      },
    }),
    singleValue : (styles) => ({...styles,
      top:'3px',
      position: "relative",
    }),
    placeholder : (styles) => ({...styles,
      top:'3px',
      position: "relative",
    }),
    control: (styles) => ({ ...styles, backgroundColor: 'transparent',
      border: '1px solid #ebebeb',
      color: '#333',
      fontSize: '14px',
      margin : "10px 0px !important",
      position: "relative",
      borderRadius:0,
      height:"38px",
      textAlign:"left !important",
      '& > div' : {
        height: "100% !important",
        display: "block !important",
        overflow: "unset !important",
      },
    }),
  };

  useEffect(()=>{
    if(sortSizeSetting?.length) {
      setDefaultSizeForAll(sortSizeSetting.map(item => item.value.toLowerCase()));
    }
  },[sortSizeSetting])

  const artworksListState = useSelector((state) => state.productData.artworkList);
  const artworksProductState = useSelector((state) => state.productData.artworkListById);
  const artworksFromStateAll =  [...artworksListState, ...artworksProductState]
  const productStockState = useSelector((state) => state.productData.productStock);
  const {
    getDecoPrice,
    autoAttachDecoration
  } = useProductArtworkState({
    artworksFromState: artworksFromStateAll,
    setLoading: (loading) => setLoading(loading)
  })

  const {
    getDecoPriceByQuantity,
    getDecoChargesByQuantity,
    getAdditionalDecorationCharge
  } = useProductDecoCharge({ artworksFromState: artworksFromStateAll })


  const {
    getInventorySettingStatus,
    checkMinQty,
    orderAction,
    getSettingStatus,
    getCartItemTotal,
    getLocationsCondensed,
  } = useProductConfigState({
    cartItems,
    catalogueCustomRows,
    product,
    artworksProductState: artworksProductState,
    artworksFromState: artworksFromStateAll,
    artworkSetting,
    selectedLocation,
    selectedLocationIndex
  })

  let todayDates = new Date().toISOString()

  useEffect(() => {
    setTimeout(() => {
      animateScroll.scrollToTop()
    }, 500);
  },[imageScroll])
    
    const isColorValid = (color) => {
    return !!color && color !== `Select ${colorLabel}`;
  }

  const getStoreArtwork = () => {
    const requestOptions = {
      headers: {
        'Content-Type': 'application/json',
        'X-TENANT-ID': X_TENANT_ID,
      },
    }
    Axios
        .get('/api/v1/artworkdata/', requestOptions)
        .then((res) => {
          if (res.data.error) {
            _addToast(res.data.message, {
              appearance: 'error',
              autoDismiss: true,
            })
          } else {
            setStoreArtwork(res.data)
          }
        })
        .catch((err) => {
          console.log('err', err)
        })
  }

  useEffect(() => {
    getStoreArtwork()
  }, [])

  useEffect(() => {
    if(!!product?.relabelColor) {
        setColorLabel(product.relabelColor)
    }
    if(!!product?.relabelSize) {
        setSizeLabel(product.relabelSize)
    }
  },[product])

  useEffect(() => {
    setTimeout(() => {
      let element = document.querySelectorAll('.swiper-slide-active .containerimg img');
      if(isModel) {
        element = document.querySelectorAll('.modalportal .swiper-slide-active .containerimg img');
      }
      setImageW(element[0]?.offsetWidth)
      setImageH(element[0]?.offsetHeight)
    }, 1000);
  }, [selectedDecoration]);

  useEffect(() => {
    setTimeout(() => {
      let element = document.querySelectorAll('.swiper-slide-active .containerimg img');
      if(isModel) {
        element = document.querySelectorAll('.modalportal .swiper-slide-active .containerimg img');
      }
      setImageW(element[0]?.offsetWidth)
      setImageH(element[0]?.offsetHeight)
    }, 500);
  },[imgW, imgH]);

  let getSingleImage = ""
  const minQuantity = () => {
    let allQty = [];
    let minObj = partPrice.reduce(function (prev, curr) {
      return Number(prev.minQuantity) < Number(curr.minQuantity) ? prev : curr
    })
    setMinQty(minObj.minQuantity)
    allQty = partPrice.map((item) => {
      return Number(item.minQuantity)
    })
    setMinAllQty(allQty)
  }

  useEffect(() => {
    if (product?.showColorProd === true || product?.showColorProd === false) {
      setShowColor(product?.showColorProd)
    } else {
      setShowColor(productSettings?.showColor)
    }
    if (product?.showSizeProd === true || product?.showSizeProd === false) {
      setShowSize(product?.showSizeProd)
    } else {
      setShowSize(productSettings?.showSize)
    }
    if (productSettings && productSettings.disableProductClone === false) {
      setIsdisableProductClone(false)
    } else {
      setIsdisableProductClone(true)
    }
    if(!!product?.AllowOneDecorationLimitPro && product?.AllowOneDecorationLimitPro !== " ") {
      setallowOneDec(product?.AllowOneDecorationLimitPro)
    } else {
      setallowOneDec(productSettings?.AllowOneDecorationLimit)
    }
    if(product?.showAddVariation === true || product?.showAddVariation === false) {
      setShowAddVariation(product.showAddVariation);
    } else {
      setShowAddVariation(productSettings?.showAddVariation);
    }
    if(product?.RequiredDecorationForCheckOutPro === true || product?.RequiredDecorationForCheckOutPro === false) {
			setRequiredDecorationForCheckOutPro(product?.RequiredDecorationForCheckOutPro)
		} else {
			setRequiredDecorationForCheckOutPro(productSettings?.RequiredDecorationForCheckOut)
		}
    if (product?.showDescription === true || product?.showDescription === false) {
      setProductSettingShowDescription(product?.showDescription)
    } else {
      setProductSettingShowDescription(productSettings?.showDescription)
    }
    if (product?.showDecorationCostInCart === true || product?.showDecorationCostInCart === false) {
      setShowDecorationCostInCart(product?.showDecorationCostInCart)
    } else {
      setShowDecorationCostInCart(productSettings?.showDecorationCostInCart)
    }
    if (product?.showSetupChargeInCart === true || product?.showSetupChargeInCart === false) {
      setShowSetupChargeInCart(product?.showSetupChargeInCart)
    } else {
      setShowSetupChargeInCart(productSettings?.showSetupChargeInCart)
    }
    if(product && productSettings && minQty) {
      setMoqNote(getMoqNote(productSettings, product, minQty));
      setShowMoqNote(getShowMoqNote(productSettings, product));
    }
    if(product?.hideDecorationName === true || product?.hideDecorationName === false) {
      setHideDecorationName(product?.hideDecorationName)
    } else {
      setHideDecorationName(!!productSettings?.hideDecorationName ? productSettings.hideDecorationName : false)
    }
    if(product?.showPersonalizationCostInCart === true || product?.showPersonalizationCostInCart === false) {
      setShowPersonalizationCostInCart(product?.showPersonalizationCostInCart);
    } else {
      setShowPersonalizationCostInCart(productSettings?.showPersonalizationCostInCart);
    }
    verifyShowSize();
    verifyShowColor();
    if(!!product?.decimalsToShow) {
      setDecimalsToShow(Number(product?.decimalsToShow))
    } else if(!!productSettings?.decimalsToShow) {
      setDecimalsToShow(Number(productSettings?.decimalsToShow))
    } else {
      setDecimalsToShow(2);
    }
    if (product?.hideLocationName === true || product?.hideLocationName === false) {
			setHideLocationName(product?.hideLocationName)
		} else {
			setHideLocationName(!!productSettings?.hideLocationName ? productSettings.hideLocationName : false)
		}
  }, [product, productSettings, minQty])

  useEffect(() => {
    if(product?.personalizationDropDown === true || product?.personalizationDropDown === false) {
      setPersonalizationDropDown(product?.personalizationDropDown)
      setRequiredLinesPersonalization(product?.requiredLinesPersonalization)
      setDropDownValues(product?.dropDownValues)
    } else if(productSettings?.personalizationDropDown === true || productSettings?.personalizationDropDown === false) {
      setPersonalizationDropDown(productSettings?.personalizationDropDown)
      setRequiredLinesPersonalization(productSettings?.requiredLinesPersonalization)
      setDropDownValues(productSettings?.dropDownValues)
    }
    if (product?.personalizationRequired === true || product?.personalizationRequired === false) {
      setPersonalizationRequired(product?.personalizationRequired)
    } else if (productSettings?.personalizationRequired === true || productSettings?.personalizationRequired === false) {
      setPersonalizationRequired(productSettings?.personalizationRequired)
    }
  },[product, productSettings])

  useEffect(() => {
    if(product?.allowCustomDecoration === true || product?.allowCustomDecoration === false) {
      setAllowCustomDecoration(product?.allowCustomDecoration)
    } else {
      setAllowCustomDecoration(!!productSettings?.allowCustomDecoration ? productSettings.allowCustomDecoration : false)
    }
  },[productSettings, product])

  useEffect(() => {
    if(product && product.ProductPartArray && product.ProductPartArray.ProductPart){
      for(const productPart of product.ProductPartArray.ProductPart){
        partIds.push(
          {
            partId: productPart.partId,
            color: productPart.ColorArray?.Color?.colorName,
            size: productPart.ApparelSize?.labelSize
          }

        )
      }
    }
  }, [product, partIds])

  useEffect(() => {
    if (personalizationRequired) {
      addPersonalization();
      setPersonalizationAdded(true);
    }
  }, [personalizationRequired])

  useEffect(() => {
    verifyShowColor();
  }, [productSettings, productDstInventory, productInventory])

  useEffect(()=>{
    if ( product?.preventAutoAttach === true || product?.preventAutoAttach === false ) {
      setPreventAutoAttach(product?.preventAutoAttach)
    } else {
      setPreventAutoAttach(!!productSettings?.preventAutoAttach ? productSettings.preventAutoAttach : false)
    }
  }, [product, productSettings])

  useEffect(() => {
    if(!!!product.isStockFor || product.isStockFor === 'globalsetting') {
      setIsStockFor(isStock)
    } else {
      setIsStockFor(product.isStockFor)
    }
  }, [product, isStock])

  useEffect(() => {
    if (partPrice && partPrice?.length > 0) {
      minQuantity()
    }
  }, [partPrice])


  useEffect(() => {
    setIsTrue(false)
    setImageOnPageLoad(product?.MediaContent?.[0]?.url)
    setPrimaryImage(product?.MediaContent && product?.MediaContent.find((dd) => dd?.primary === true ? dd?.url : ""))
    setIsMoq(product?.isProductMoq)
    setIsPriceBreak(product?.doNotCalculateAtOrder == "1" ? false : product?.isPriceBreak)
    setIsSkuMoq(product?.isProductskuMoq)
    setIsRestrictQty(product?.isRestrictQty)
    setPartPrice(
        product?.WebstoreProductPartArray[0]?.partPrice?.PartPriceArray
            ?.PartPrice ?? product?.ProductPartArray?.ProductPart?.[0]?.partPrice?.PartPriceArray
            ?.PartPrice,
    )
    setProdId(product?.productId)
    setProductId(product?.id)
    setIsSearchArtwork(product?.searchArtwork)

    setCustomDecorationFee(product?.customDecorationFee)
    setCustomDecorationPerUnitPrice(product?.customDecorationPerUnitPrice ?? 0)
    setPersonalizationPerUnitPrice(product?.personalizationPerUnitPrice)
    setPersonalizationSetupFee(product?.personalizationSetupFee)
    setCustomDecorationSetupFee(product?.customDecorationSetupFee)
    let cartCustomRows = null
    cartCustomRows = product?.MediaContent.filter(x => x.primary).map(x => {

      return {
        id: uniqIdcounter++,
        size: '',
        quantity: '',
        color: '',
        displayGroup: x.displayGroup,
        group: x.group,
        groups: x.groups,
        hex: x.hex,
        hidden: x.hidden,
        index: x.index,
        logoBlockCount: x.logoBlockCount,
        mediaType: x.mediaType,
        partId: x.partId,
        primary: x.primary,
        productId: x.productId,
        secondary: x.secondary,
        select: x.select,
        sku: x.sku,
        url: x.url,
        price: 0,
        totalPrice: 0,
        cost: 0,
        totalCost: 0,
        product: {sku: x.sku},
        weight: product.weight,
        shipFrom: {
          countryFrom: product.shipFromCountry,
          zipCodeFrom: product.shipFromPostalCode,
        },
      }
    });
    cartCustomRows = cartCustomRows.length > 0 ? [cartCustomRows] : [
      [
        {
          id: uniqIdcounter++,
          size: '',
          quantity: '',
          image: product?.MediaContent?.[0]?.url,
          color: "",
          product: {sku: 0},
          price: 0,
          totalPrice: 0,
        },
      ],
    ]

    setPersonalizationCost((preVal) => ({
      ...preVal,
      ['matchOrderQty']:
      cartCustomRows[0][0]?.personalizationCost?.matchOrderQty,
      ['price']: cartCustomRows[0][0]?.personalizationCost?.price,
      ['chargeId']: cartCustomRows[0][0]?.personalizationCost?.chargeId,
    }))
    setCatalogueCustomRows(cartCustomRows)
    setTimeout(() => {
      setIsTrue(true)
    }, 1000);

  }, [product])

  useEffect(() => {
    if(product && !!product?.decorationListOrder) {
      setDecorationListOrder(product?.decorationListOrder)
    }
  }, [product])

  useEffect(() => {
    let key = 0
    if (selectedImage) {
      let temp = product?.WebstoreProductPartArray.filter((val) => val.image === selectedImage && val?.isSwapImage,)
      if (temp.length > 0) {
        if (product?.WebstoreProductPartArray) {
          product.WebstoreProductPartArray.filter(
              (ele, ind) => ind === product?.WebstoreProductPartArray.findIndex(elem => elem.color === ele.color)).map((single, index) => {
            if (single.image === selectedImage) {
              return key = index
            }
          })
        }
      } else {
        if (product.MediaContent) {
          product.MediaContent.filter((mediaArray) => !mediaArray.hidden).map((single, index) => {
            if (single.url === selectedImage) {
              key = index
            }
          })
        }
      }

      if (document.querySelectorAll('.swiper-pagination-bullet')) {
        let color_image_url = ''
        if (document.querySelector('#sw1')) {
          if (
              document.querySelector('#sw1').querySelector('.swiper-slide-active')
          ) {
              color_image_url = document
                  .querySelector('#sw1')
                  .querySelector('.swiper-slide-active')
                  .querySelector('img').src

          }
        }
        if (color_image_url !== selectedImage) {
          let frameZones = Array.from(
              document.querySelectorAll('.swiper-pagination-bullet'),
          )
          if (isModel) {
            frameZones = Array.from(
              document.querySelectorAll('.modalportal .swiper-pagination-bullet'),
            )
          }
          let flag = true
          frameZones &&
          frameZones.map(async (el, k) => {
            if (k === key && flag) {
              flag = false
              let opts = {
                view: window,
                bubbles: true,
                cancelable: true,
                buttons: 1,
              }
              el.dispatchEvent(new MouseEvent('mousedown', opts))
              await new Promise((r) => setTimeout(r, 50))
              el.dispatchEvent(new MouseEvent('mouseup', opts))
              el.dispatchEvent(new MouseEvent('click', opts))
            }
          })
        }
      }
    }
  }, [selectedImage, IsSelectedImageChng])

  useEffect(() => {
    if (processSingle) {
      setProcessSingle(false)
      addtoCart()
    }
  }, [processSingle])
  const searchArtworkName = (e) => {
    setTextSearchArtwork(e.target.value)
  }
  
  const verifyShowSize = () => {
    if(priceTable.length === 1) {
      setSelectedProductSize(priceTable[0].size);
    }
    if (!showSize && priceTable.length > 1) {
      setShowSize(true);
    }
  }
  const verifyShowColor = () => {
    if (colors_final.length === 1) {
      setSelectedProductColor(colors_final[0]);
      setTempSelectedColor(colors_final[0]);
      setIsReplace(true);
      let tempRows = catalogueCustomRows.filter((rowGroupArray) => {
        rowGroupArray.filter((rowArray) => {
          rowArray.color = colors_final[0]
          return rowArray
        })
        return rowGroupArray
      })
      if(tempRows.length > 0) {
        setCatalogueCustomRows(tempRows)
        setTempValue(tempValue + 1)
      }
    }
    if(colors_final.length > 1 && showColor === false) {
      setShowColor(true);
    }
  }

  useEffect(() => {
    verifyShowSize()
    verifyShowColor()
  }, [showColor, showSize])

  const wishlistItem = wishlistItems.filter(
      (wishlistItem) => wishlistItem.id === product.id,
  )[0]
  const compareItem = compareItems.filter(
      (compareItem) => compareItem.id === product.id,
  )[0]

  var rWithoutDiscPrice = 0

  useEffect(() => {
    getAlertMessageProductDecoration()
  }, [])

  const fetchPersonalizationCost = async () => {
    /** Api for Personalization cost. Its get from advance; **/
    // setLoading(true)
    try {
      let result = await advanceAPIUtil.customAdvanceCall(addOnChargesPersBody)
      if (result?.data?.data) {
        let personalizationData = result?.data?.data.filter(
            (val) => val.itemCode === 'PERS',
        )
        if (personalizationData) {
          setPersonalizationCost((preVal) => ({
            ...preVal,
            ['matchOrderQty']: 1,
            ['price']: personalizationPerUnitPrice ? personalizationPerUnitPrice : 0,
            ['setupPrice']: personalizationSetupFee ? personalizationSetupFee : 0,
            ['chargeId']: personalizationPerUnitPrice ? '' : ''
          }))
          totalPriceData()
        }
      }
    } catch (error) {
      console.log('error', error)
    }
  }

  if (catalogueCustomRows?.length > 0) {
    catalogueCustomRows.map((rowArray) => {
      rowArray && rowArray.length > 0 && rowArray.map((rowArrayInner) => {
        if (Number(rowArrayInner.id) >= Number(uniqIdcounter)) {
          uniqIdcounter = Number(rowArrayInner.id) + 1
        }
      })
    })
  }

  useEffect(() => {
    // console.log('productStockState: ', productStockState, " ==== isModel === ", isModel,  " ==== fetchedInvProductId === ", fetchedInvProductId,  " ==== product.id === ", product.id );
    if((!!!productStockState || (isModel && (fetchedInvProductId === ""  || (!!fetchedInvProductId && fetchedInvProductId !== product.id)))) && isProductInventoryEnabled) {
      fetchProductStock(product.id)
      setFetchedInvProductId(product.id)
    }
  },[productStockState, product])
  
  useEffect(() => {
    if (storeDetails?.accountId) {
      setLoadingartworks(true)
      fetchArtworksById({
        account_id: storeDetails.accountId,
        product_id: product.id,
        userId: user?.userId || undefined,
        categories: product.ProductCategoryArray|| [],
        restrict: true,
      }).then(x => {
        setArtworksToRead(x)
      }).catch(err => {
        console.log(err)
      }).finally(() => setLoadingartworks(false))
    }
  }, [storeDetails, product.id])

  useEffect(() => {
    const locations = getLocationsCondensed();
    if (locations?.locationIds?.length > 0 && isFirstTime) {
      setIsFirstTime(false)
      fetchDecorationLocations(locations.locationIds)
    } else if(locations?.locationIds?.length === 0 && isFirstTime) {
      dispatch(
          receiveDecorationLocations({
                data: []
              }
          ),
      )
      setIsFirstTime(false)
    }
  }, [product, catalogueCustomRows, artworksFromStateAll, selectedProductColor])

  useEffect(()=>{
    setArtworks()
  }, [artworkListById, artworkList])

  useEffect(()=>{
    setTimeout(() => {
      setArtworks()
    }, 3000);    
  }, [isNewArtWorkAdded])

  const setArtworks = () => {
    if ((artworkListById?.length > 0 || artworkList?.length > 0)) {
      const list = artworkList?.length ? artworkList : []
      const listById = artworkListById?.length ? artworkListById : []
      setArtworksToRead( [...list, ...listById])
    }
  }

  let productPrice = product.price ? product.price : 0
  let productDiscount = product.discount ? product.discount : 0
  const discountedPrice = getDiscountPrice(productPrice, productDiscount)
  const handleCancel = () => {
    setAddnewart(false)
  }
  const handleAddnewArt = (addedNewartdata) => {
    let artwork = addedNewartdata.newArtData
    let locationID = addedNewartdata.location
    let showAddnewartIdS = addedNewartdata.showAddnewartId
    fetchArtworksById({
      account_id: storeDetails.accountId,
      product_id: product.id,
      userId: user?.userId || undefined,
      categories: product.ProductCategoryArray || [],
      restrict: true,
    })
    setAddnewart(false)
    setLoading(true);
    if (artwork.id) {
      setSelectedProductArtwork(artwork.id)
      if (artwork?.design?.variation) {
        if (artwork.design.variation.length === 1) {
          let variation = artwork.design.variation[0]
          let tempDecoArray = selectedProductDecorations
            ? selectedProductDecorations
            : []
          let locationArray = decorationLocations?.filter(
            (locationArray) => locationArray?.locationName === locationID,
          )
          let decoObj = {
            artworkID: artwork.id,
			      artworkSource:artwork.artworkSource,
            location: locationArray?.[0]?.locationName,
            locationId: locationArray?.[0]?.id,
            variationUniqID: variation.design_variation_unique_id,
          }
          let getDecoOfLocation = tempDecoArray.filter(val => val.locationId === locationArray?.[0]?.id)
          if (getDecoOfLocation.length === 0) {
            tempDecoArray.push(decoObj)
            setSelectedProductDecorations(tempDecoArray)
          }
          else if (getDecoOfLocation.length === 1) {
            let replaceTempDecoArray = tempDecoArray.filter(val => val.locationId !== locationArray?.[0]?.id)
            tempDecoArray = replaceTempDecoArray
            tempDecoArray.push(decoObj)
            setSelectedProductDecorations(tempDecoArray)
          }
          let tempcatalogueCustomRows = catalogueCustomRows.filter(
            (rowGroupArray, groupkey) => {
              if (groupkey === showAddnewartIdS) {
                rowGroupArray.filter((rowArray) => {
                  if(rowArray?.decorations)
                    rowArray.decorations = tempDecoArray
                  return rowArray
                })
              }
              return rowGroupArray
            },
          )
          setCatalogueCustomRows(tempcatalogueCustomRows)
          temp(showAddnewartIdS, artwork.id, locationArray?.[0]?.locationName)
          setIsNewArtWorkAddedMain(isNewArtWorkAddedMain + 1);
          setSelectedProductLocation('')
          setAppliedFirstDeco(true)
          // setSelectedProductArtwork('')
        }
      }
    }
  }

  useEffect(() => {
    if (artworksFromStateAll?.length && selectedProductColor && !appliedFirstDeco) {
      setTimeout(async () => {
        setAppliedFirstDeco(true)
        await applyFirstColor(colors_final.length === 1 ? colors_final[0] : selectedProductColor)
      }, 1)
    }
  }, [artworksProductState])

  const applyFirstColor = async (color) => {
    let getImage = product?.WebstoreProductPartArray.filter((val) => val.color === color && val.isSwapImage === true,)
    if (getImage.length === 0) {
      if (getSettingStatus('showDecoration') || !getSettingStatus('showDecoration')) {
        setIsAdded(false)
        let findProductArtvariationId = ''
        let locationId = ''
        if (artworksFromStateAll && artworksFromStateAll.length) {
          const numOfArtwork = artworksFromStateAll.reduce((acc, val) => acc +
              (val.productToDecorationMapping || []).filter(x => x.isRequiredForStore === '1' && x.mapping.filter(m => m.color === color)).length, 0
          )
          let keyMap = 0
          artworksFromStateAll.map((val, key) => {
            (val?.productToDecorationMapping || []).forEach((decoMap) => {
              if (decoMap.isRequiredForStore === '1') {
                locationId = decoMap?.location
                decoMap.mapping.map(async (mapVal) => {
                  if (mapVal.color === color) {
                    handleCheckbox(locationId, 0)
                    findProductArtvariationId = mapVal.variationId
                    let isRequiredForStore = decoMap.isRequiredForStore
                    let supplierDeco = decoMap.supplierDeco
                    await handleArtworkselection(
                        val,
                        0,
                        locationId,
                        findProductArtvariationId,
                        keyMap++ ===  (numOfArtwork - 1),
                        isRequiredForStore,
                        supplierDeco
                    )
                  }
                })
              } else {
                setShowData('yes')
              }
            })
          })
        }
      }
      setIsReplace(true)
    } else {
      setIsReplace(false)
    }
  }
 
  useEffect(() => {
    if(isNewArtWorkAddedMain > 0) {
      setTimeout(() => {
        setLoading(false)
      }, 10000);
    }
  }, [isNewArtWorkAddedMain])

  const addPersonalization = (key) => {
    /*** get personalization cost from advance
     its call one time if personalizationCost less than 0 or 0. ***/

    if (!personalizationCost.price) {
      fetchPersonalizationCost()
    }

    let tempData = catalogueCustomRows.filter((rowGroup, index) => {
      if (index === key || !key) {
        rowGroup.map((rowArray) => {
          if (!rowArray.personalization) {
            rowArray['personalization'] = []
          }
          let persQty = rowArray.quantity
          let tempMainrows = rowArray.personalization.filter(
              (row) => Number(row.sequance) === 1,
          )
          let persRows = []
          if (Number(persQty) > 0) {
            let itemQty = Number(persQty)
            let pushedIds = []
            for (let index = 0; index < itemQty; index++) {
              if (tempMainrows[index]) {
                let tempPersRow = rowArray.personalization.filter(
                    (row) => row.rowQtyNo === tempMainrows[index].rowQtyNo,
                )
                if (pushedIds.indexOf(tempPersRow[0].rowQtyNo) === -1) {
                  pushedIds.push(tempPersRow[0].rowQtyNo)
                  tempPersRow.map((tempRow) => {
                    persRows.push({
                      ...tempRow,
                      itemSize: rowArray.size,
                      itemColor: rowArray.color,
                      rowId: rowArray.id,
                    })
                  })
                }
              } else {
                if(!!personalizationDropDown && !!requiredLinesPersonalization) {
                  !!dropDownValues && dropDownValues.length > 0 && dropDownValues.map((resData, keysData) => {
                    persRows.push({
                      color: getPersColor(product, productSettings),
                      displayText: '',
                      font: getPersFont(product, productSettings),
                      itemCode: product.itemCode,
                      itemColor: rowArray.color,
                      itemId: product.id,
                      itemNo: product.productId,
                      itemSize: rowArray.size,
                      location: getPersLocation(product, productSettings),
                      notes: getPersNote(product, productSettings),
                      orderId: '',
                      rowId: rowArray.id,
                      rowQtyId: '',
                      rowQtyNo: index + 1,
                      key : key,
                      sequance: 1 + keysData,
                      lineName: resData.personalizationDropDownRename,
                      isDrp: resData.isPersonalizationDropDown,
                      drpValue: resData.personalizationDropDownValue,
                      isEnabled: personalizationDropDown,
                      requiredLine: requiredLinesPersonalization,
                    })
                  })
               } else {
                persRows.push({
                  color: getPersColor(product, productSettings),
                  displayText: '',
                  font: getPersFont(product, productSettings),
                  itemCode: product.itemCode,
                  itemColor: rowArray.color,
                  itemId: product.id,
                  itemNo: product.productId,
                  itemSize: rowArray.size,
                  location: getPersLocation(product, productSettings),
                  notes: getPersNote(product, productSettings),
                  orderId: '',
                  rowId: rowArray.id,
                  rowQtyId: '',
                  rowQtyNo: index + 1,
                  sequance: 1,
                })
               }
                if(rowArray?.extraParam === 'onCopyRow') {
                  ToggleDivs(index + 1, 'P', 'ForOnCopyRow')
                }
              }
            }
          }
          rowArray.personalization = persRows
          return rowArray
        })
      }
      return rowGroup
    })
    //add personalization
    setCatalogueCustomRows(tempData)
    updatestate(!statchange)
  }

  useEffect(() => {
    getArtworkLocations()
  }, [])

  const getArtworkLocations = () => {
    productsAPIUtil.getEnabledArtworkLocations(product._id).then(response => {
        setArtworkLocations(response.data);
    }).catch(error => {
        console.log('error', error);
        _addToast('Error while loading data!', {
            appearance: "error",
            autoDismiss: true
        });
    })
  }

  const removeDeco = (mainArraykey, itemsRows, index) => {
    let tempData = catalogueCustomRows.filter((rowGroupArray, groupkey) => {
      if (groupkey === mainArraykey) {
        rowGroupArray = rowGroupArray.filter((groupArray) => {
          if (groupArray.decorations) {
            if(index !== '' && index !== null)
              groupArray.decorations.splice(index, 1)
            else 
              groupArray.decorations = [];
          }
          return groupArray
        })
      }
      return rowGroupArray
    })

    //remove deco
    //setNotCallDecoHtml(true)
    //ToggleDivs(index, 'D')
    setCatalogueCustomRows(tempData)
    //setSelectedLocation('')
    //setSelectedLocationIndex('')
    updatestate(!statchange)
  }

  const showPopupText = async (mainArraykey, itemsRows, index) => {
    if(product.restrictAfterFirstChoice) {
      setIndexPopup(index)
      setMainArrayKeyPopup(mainArraykey)
      setItemsRowsPopup(itemsRows)
      setShowDecoPopup(true);
    } else {
      removeDeco(mainArraykey, itemsRows, index);
    }
  }

  const getAlertMessageProductDecoration = () => {
    if(product._id) {
      productsAPIUtil.getProductDecorationPopup(product._id).then(response => {
        if(response?.data?.alertMessage) {
          setPopupText(response?.data?.alertMessage);
        }
      }).catch(error => {
        console.log('error', error);
        _addToast('Error while loading data!', {
              appearance: "error",
              autoDismiss: true
          });
      })
    }
  }

  const photoZoom = (zmImg = '')=>{

    let node = document.querySelector(".swiper-slide-active .single-image.lightGal");
    if(isModel) {
      node = document.querySelector(
        '.modalportal .swiper-slide-active .single-image.lightGal',
      )
    }
    let getOuterBOxCheck = document.querySelector('.lg-img-wrap .single-image.lightGal')


    let zoomPictureNode = document.getElementsByClassName("lg-img-wrap")
    if ( !!zoomPictureNode && !!zoomPictureNode?.[0] && !!DefaultProductBackgroundColor && zmImg !== '' )
      zoomPictureNode[0].classList.add(zmImg)

    if ( node ) {
      const clone = node.cloneNode(true);
      zoomPictureNode[0].classList.add('lg-img-zoom')
      if ( zoomPictureNode ) {
        zoomPictureNode[0].appendChild(clone);
      }
      var fourChildNode = zoomPictureNode[0].querySelector('.containerimg');
      if ( !!fourChildNode ) {
        fourChildNode.style.maxWidth = 'unset'
        fourChildNode.style.width = 'unset'
      }
      let fourChildNode0 = zoomPictureNode[0].querySelector('.containerimg .react_lightgallery_item');
      let fourChildNode1 = zoomPictureNode[0].querySelector('.containerimg .react_lightgallery_item .img-fluid');
      console.log('fourChildNode1: ', fourChildNode1?.width);
      if (!!fourChildNode1 && fourChildNode1?.width > 2000) {
        fourChildNode1.style.height = 'auto'
        fourChildNode0.style.display = 'flex'
        fourChildNode0.style.alignItems = 'center'
        fourChildNode0.style.justifyContent = 'center'
      }
    }

    //get outerbox
    let getOuterBOx = document.querySelector('.lg-img-wrap .react_lightgallery_item')
    if ( !!getOuterBOx ) {
      let imgWidthZoom = getSingleImage?.iw
      let imgHeightZoom = getSingleImage?.ih
      let singleZoomX = getSingleImage?.x
      let singleZoomY = getSingleImage?.y
      let singleZoomW = getSingleImage?.w
      let singleZoomH = getSingleImage?.h

      if(getSingleImage?.isNewLogo == "1" && !!getSingleImage?.processedImage) {
        imgWidthZoom = getSingleImage?.processedImageWidth
        imgHeightZoom = getSingleImage?.processedImageHeight
        singleZoomX = getSingleImage?.newX
        singleZoomY = getSingleImage?.newY
        singleZoomW = getSingleImage?.newW
        singleZoomH = getSingleImage?.newH
      }
      let imgWidth = imgWidthZoom
      let imgHeight = imgHeightZoom

      let viewPortHeight = window.innerHeight
      let reducePer = getReducePer(imgHeight, viewPortHeight)

      imgWidth = (imgWidth * reducePer) / 100
      imgHeight = (imgHeight * reducePer) / 100

      // add left top postion to artwork
      let positionSetLeft = (getOuterBOx.offsetWidth * singleZoomX) / imgWidthZoom
      let positionSetTop = (getOuterBOx.offsetHeight * singleZoomY) / imgHeightZoom
      let positionSetBoxWidth = (getOuterBOx?.offsetWidth * singleZoomW) / imgWidthZoom
      let positionSetBoxHeight = (getOuterBOx?.offsetHeight * singleZoomH) / imgHeightZoom


      let overlayImag = document.querySelectorAll(".lg-img-wrap .overlayimg")
      const css = '.overlayimg:hover{  transform: scale(1.5); }';
      const style = document.createElement('style');
      if (style.sheet) {
        style.sheet.cssText = css;
      } else {
        style.appendChild(document.createTextNode(css));
      }
      for ( let i = 0; i < overlayImag.length; i ++ ) {
        overlayImag[i].style.left = positionSetLeft + "px"
        overlayImag[i].style.top = positionSetTop + "px"
        overlayImag[i].style.width = positionSetBoxWidth + "px"
        overlayImag[i].style.height = positionSetBoxHeight + "px"
        overlayImag[i].style.transition = 'transform 0.3s';
        overlayImag[i].appendChild(style)
      }

      //add width height to artwork

      let overlayImag2 = document.querySelectorAll(".lg-img-wrap .overlayimg .img-fluid")
      for ( let i = 0; i < overlayImag2.length; i ++ ) {
        overlayImag2[i].style.maxWidth = positionSetBoxWidth + 'px'
        overlayImag2[i].style.maxHeight = positionSetBoxHeight + 'px'
        overlayImag2[i].style.width = '100%'
        overlayImag2[i].style.height = '100%'
        overlayImag2[i].style.objectFit = 'contain'
      }
    }
    let getOuterBOxCheck2 = document.querySelector('.lg-img-wrap .lightGal')

    let zoomInBtn = document.getElementById('lg-zoom-in');
    let zoomOutBtn = document.getElementById('lg-zoom-out');

    let scaleItem = 1

    zoomInBtn.addEventListener('click', function (event) {
      getOuterBOxCheck2.style.transform = `scale(${ scaleItem + 0.5 }) translateX(-50%)`
      scaleItem = scaleItem + 0.5
    });
    zoomOutBtn.addEventListener('click', function (event) {
      getOuterBOxCheck2.style.transform = `scale(${ scaleItem - 0.5 }) translateX(-50%)`
      scaleItem = scaleItem - 0.5
    });
  }

  const getReducePer = (width, param) => {
    let reducePer = 100
    while ((Number(width) * reducePer) / 100 > param) {
      reducePer = reducePer - 1
    }
    return reducePer
  }
  useEffect(() => {
    if (
        gallerySwiper !== null &&
        gallerySwiper.controller &&
        thumbnailSwiper !== null &&
        thumbnailSwiper.controller
    ) {
      gallerySwiper.controller.control = thumbnailSwiper
      thumbnailSwiper.controller.control = gallerySwiper
    }
  }, [gallerySwiper, thumbnailSwiper])

  const swipeimage = product?.MediaContent?.filter(
      (mediaArray) => !mediaArray.hidden,
  ).map((mediaElement, key) => {
    let single = null
    let findlogoinfo = ''
    let productColorList = catalogueCustomRows.filter(val => val[0].color === mediaElement.color)
    let getImage = product?.WebstoreProductPartArray.filter((val) => val.color === mediaElement.color && val.isSwapImage === true,)
    let prImage = mediaElement.url
    let newImage = mediaElement.url
    if(mediaElement?.isNewLogo == "1" && !!mediaElement?.processedImage) {
      prImage = mediaElement.processedImage
    }
	  if (getImage.length > 0) {
		  prImage = getImage[0].image
	  }
    if (productColorList?.length > 0 && getSettingStatus('showDecoration')) {
      let getDecoData = productColorList[0][0]?.decorations
      if (selectedDecoration !== "" && selectedDecoration !== null && selectedDecoration !== "null" && selectedDecoration !== undefined) {
        for (let i = 0; i < product?.LogoInfo.length; i++) {
          const logoInfoData = product?.LogoInfo[i]
          if (logoInfoData.color === mediaElement.color) {
            if (mediaElement.url === logoInfoData.image && logoInfoData.location === selectedDecoration.location) {
              findlogoinfo = logoInfoData
              break;
            } else if (logoInfoData.location === selectedDecoration.location) {
              findlogoinfo = logoInfoData
              break;
            } else if (mediaElement.url === logoInfoData.image) {
              let extra_logo = logoInfoData.extra_logo.filter(val => val.location === selectedDecoration.location)
              if (extra_logo.length > 0) {
                findlogoinfo = extra_logo
                findlogoinfo[0].image = logoInfoData.image
                findlogoinfo[0].color = logoInfoData.color
                findlogoinfo[0].ih = logoInfoData.ih
                findlogoinfo[0].iw = logoInfoData.iw
                if (logoInfoData?.isNewLogo == "1" && !!logoInfoData?.processedImage) {
                  findlogoinfo[0].processedImageWidth = logoInfoData?.processedImageWidth
                  findlogoinfo[0].processedImageHeight = logoInfoData?.processedImageHeight
                  findlogoinfo[0].isNewLogo = logoInfoData?.isNewLogo
                  findlogoinfo[0].processedImage = logoInfoData?.processedImage
                }
                break;
              }
            }
          }
        }
      } else if (getDecoData?.length > 0) {
        getDecoData.map(deco => {
          return findlogoinfo = product.LogoInfo.filter(function (val) {
            return val.image === mediaElement.url &&
                val.location === deco.location && deco.variationUniqID !== "";
          });
        })
      }
    }


    if (findlogoinfo !== '' && findlogoinfo?.length) {
      //setTempSelectedColor(mediaElement.color)
      single = findlogoinfo[0]
      getSingleImage = single
    } else if (findlogoinfo !== '') {
      single = findlogoinfo
      getSingleImage = single
    }
    if (single !== null && single !== '' && single && !mediaElement.hidden) {
      let selectedRow = null
      let decoselected = []
      if (catalogueCustomRows?.length) {
        catalogueCustomRows.map((groupArray) => {
          groupArray.map((row) => {
            if (row.color === single.color) {
              if (currentqid || currentActiveId) {
                if (currentqid === row.id || currentActiveId === row.id) selectedRow = row
              } else {
                selectedRow = row
              }
            }
          })
        })
        if (selectedRow?.id) {
          if (selectedRow.decorations) {
            decoselected = selectedRow.decorations
          }
        }
      }
      let imgWidth = single?.iw
      let imgHeight = single?.ih
      let singleX = single?.x
      let singleY = single?.y
      let singleW = single?.w
      let singleH = single?.h
      if(single?.isNewLogo == "1" && !!single?.processedImage) {
        imgWidth = single?.processedImageWidth
        imgHeight = single?.processedImageHeight
        singleX = single?.newX
        singleY = single?.newY
        singleW = single?.newW
        singleH = single?.newH
        prImage = single?.processedImage
      }

      if (imgWidth > 900 || imgHeight > 1100) {
        var ratio = Math.min(800 / imgWidth, 800 / imgHeight);

        imgWidth = imgWidth * ratio
        imgHeight = imgHeight * ratio
      }


      let reducePer = getReducePer(imgHeight, imageH)

      //artwork box left top position set set
      let positionSetLeft = (imageW * singleX) / imgWidth
      let positionSetTop = (imageH * singleY) / imgHeight

      //artwork box width/height set
      let positionSetBoxWidth = (imageW * singleW) / imgWidth
      let positionSetBoxHeight = (imageH * singleH) / imgHeight
      let mainCls = 'single-image lightGal'
      let zmImg = ''
      if((getImage && getImage.length > 0 && !!prImage && productImageExt(prImage)) || productImageExt(prImage))
      {
        mainCls = 'single-image lightGal product-img-bg'
        zmImg = 'product-img-bg'
      }
      return (
          <div key={key}>
            <div className={mainCls}>
              <div className="containerimg" style={(!!imageW && imageW < 450) ? {width: imageW + 'px', maxWidth: '450px'} : {maxWidth: '450px'}}>
                <LightgalleryProvider onAfterOpen={() => photoZoom(zmImg)}>
                  <LightgalleryItem
                      galleryClassName=" "
                      group="any"
                      src={getImage && getImage.length > 0 ? productImageUrl(prImage) : productImageUrl(prImage)}
                  >
                    <img
                        className="img-fluid imagei"
                        src={getImage && getImage.length > 0 ? productImageUrl(prImage) : productImageUrl(prImage)}
                        /*style={{maxWidth: '450px'}}*/
                    />
                    {artworksFromStateAll?.length > 0 && (selectedImage === prImage || newImage === selectedImage && getSettingStatus('showDecoration')) &&
                    decoselected?.map((li, key) => {

                      let to = ''
                      let lef = ''
                      let wid = ''
                      let hei = ''

                      if (li.location === single.location) {
                        to = single.y
                        lef = single.x
                        wid = single.w
                        hei = single.h
                        if(single?.isNewLogo == "1" && !!single?.processedImage) {
                          lef = single?.newX
                          to = single?.newY
                          wid = single?.newW
                          hei = single?.newH
                        }
                      } else {
                        single?.extra_logo &&
                        single.extra_logo.map((el) => {
                          if (single.location === el.location) {
                            to = el.y
                            lef = el.x
                            wid = el.w
                            hei = el.h
                            if(single?.isNewLogo == "1" && !!single?.processedImage) {
                              to = el?.newY
                              lef = el?.newX
                              wid = el?.newW
                              hei = el?.newH
                            }
                          }
                        })

                      }
                      if (to !== "") {
                        to = ((Number(to) * reducePer) / 100).toString()
                        lef = ((Number(lef) * reducePer) / 100).toString()
                        wid = ((Number(wid) * reducePer) / 100).toString()
                        hei = ((Number(hei) * reducePer) / 100).toString()
                        let artwork_selected = null
                        let artwork_selectedOld = null
                        if (li?.artworkID !== '' && li?.artworkID !== undefined) {

                          const idToSearch = selectedDecoration ? selectedDecoration.artworkID : li.artworkID
                          // console.log('idToSearch: ', idToSearch);
                          artwork_selectedOld = artworksToRead?.find(
                              (artworkArray) => artworkArray.id === idToSearch,
                          )
                          artwork_selected = artworksFromStateAll?.find(
                            (artworkArray) => artworkArray.id === idToSearch,
                          )
                        }
                       // console.log('artwork_selected: ', artwork_selected, " ===== artwork_selectedOld ==== ", artwork_selectedOld);

                        let rowVariation = null
                        if (artwork_selected?.design?.variation?.length > 0) {
                          rowVariation = artwork_selected.design.variation.filter(
                              (variationArray) =>
                                  variationArray.design_variation_unique_id ==
                                  li.variationUniqID,
                          )[0]
                          if (artwork_selected?.design?.variation?.itemImageThumbnail != "" && artwork_selected?.design?.variation?.itemImageThumbnail != undefined) {
                            rowVariation = artwork_selected?.image
                          }
                        }
                        //console.log('rowVariation: ', rowVariation);
                        if ( rowVariation && rowVariation?.itemImageThumbnail && rowVariation?.itemImageThumbnail.length > 0 ) {
                          return (
                              <div
                                  className="overlayimg"
                                  key={ key }
                                  style={ {
                                    top: positionSetTop + 'px',
                                    left: positionSetLeft + 'px',
                                  } }
                              >
                                {
                                  rowVariation && rowVariation?.itemImageThumbnail && rowVariation.itemImageThumbnail[0] ?
                                      <img
                                          className="img-fluid"
                                          src={
                                            rowVariation?.itemImageThumbnail
                                                ? productImageUrl(
                                                    rowVariation.itemImageThumbnail[0],
                                                )
                                                : ''
                                          }

                                          width={ wid + 'px' }
                                          height={ hei + 'px' }
                                          // style={{ maxWidth: wid + 'px' }}
                                          style={ {
                                            maxWidth: positionSetBoxWidth + 'px',
                                            maxHeight: positionSetBoxHeight + 'px',
                                          } }
                                      />
                                      :
                                      <img className="img-fluid"
                                           src={ rowVariation && rowVariation?.itemImageThumbnail != "" ? productImageUrl(rowVariation) : productImageUrl(rowVariation) }

                                           width={ wid + 'px' }
                                           height={ hei + 'px' }
                                           style={ {
                                             maxWidth: positionSetBoxWidth + 'px',
                                             maxHeight: positionSetBoxHeight + 'px',
                                           } }
                                      />
                                }
                              </div>
                          )
                        }
                      }
                    })}
                  </LightgalleryItem>
                </LightgalleryProvider>
              </div>
            </div>
          </div>
      )
    } else {
      return (
          <div key={key}>
            <div className="containerimg">
              <div className={`${productImageExt(prImage) ? 'product-img-bg' : ''} single-image test`}>
                <LightgalleryProvider onAfterOpen={() => photoZoom(productImageExt(prImage) ? 'product-img-bg' : '')}>
                  <LightgalleryItem
                      galleryClassName="testtt"
                      group="any"
                      src={productImageUrl(prImage)}
                  >
                    <img
                        src={productImageUrl(prImage)}
                        className="img-fluid"
                        alt="No Image"
                    />
                  </LightgalleryItem>
                </LightgalleryProvider>
              </div>
            </div>
          </div>
      )
    }
  })
  const swipeimagewebstore = product?.WebstoreProductPartArray?.filter(
      (ele, ind) => ind === product?.WebstoreProductPartArray.findIndex(elem => elem.color === ele.color)).map((mediaElement, key) => {
    let getImage = product?.WebstoreProductPartArray.filter((val) => val.color == mediaElement.color && val.isSwapImage == true,)
    if (getImage.length > 0) {
      if (!getImage[0].image) {
        const otherimagewebstore = product?.MediaContent?.filter(
            (mediaArray) => !mediaArray.hidden,
        ).map((single) => {
          getImage = product?.WebstoreProductPartArray.filter((val) => val.color === single.color && val.isSwapImage === true,)
        })
      }
      mediaElement.image = getImage[0].image
    }
    return (
        <div key={key}>
          <div className="containerimg">

            <div className={`${productImageExt(mediaElement.image) ? 'product-img-bg' : ''} single-image test`}>
              <LightgalleryProvider onAfterOpen={() => photoZoom(productImageExt(mediaElement.image) ? 'product-img-bg' : '')}>
                <LightgalleryItem
                    galleryClassName="testtt"
                    group="any"
                    src={productImageUrl(mediaElement.image)}
                >
                  <img
                      src={productImageUrl(mediaElement.image)}
                      className="img-fluid"
                      alt="No Image"
                  />
                </LightgalleryItem>
              </LightgalleryProvider>
            </div>
          </div>
        </div>
    )

  })
  const getDecoAttach = (color_current, get_image_url) => {
    setSelectedDecoration(null)
    setTempValue(tempValue + 1)
    let color_image_url = get_image_url

    if (get_image_url.includes('anterasaas.com')) {

      const url = new URL(get_image_url);
      color_image_url = url.searchParams.get('url');
    }

    let locationName = null
    let getIndex = ''
    for (let i = 0; i < catalogueCustomRows.length; i++) {
      if (catalogueCustomRows[i][0].decorations && catalogueCustomRows[i][0].decorations.length > 0) {
        if (catalogueCustomRows[i][0].color === color_current) {
          for (let p = 0; p < product.LogoInfo.length; p++) {

            if (product.LogoInfo[p].color === color_current) {

              if (product.LogoInfo[p].image === color_image_url) {
                let checkLocation = catalogueCustomRows[i][0].decorations?.filter((val) => val.location === product.LogoInfo[p].location)
                if (checkLocation?.length > 0) {
                  locationName = product.LogoInfo[p].location
                  getIndex = i
                  break;
                } else {
                  for (let e = 0; e < product.LogoInfo[p].extra_logo.length; e++) {

                    if (product.LogoInfo[p].extra_logo[e].color === color_current && product.LogoInfo[p].extra_logo[e].image === color_image_url) {

                      let checkLocation = catalogueCustomRows[i][0].decorations?.filter((val) => val.location === product.LogoInfo[p].extra_logo[e].location)
                      if (checkLocation?.length > 0) {
                        locationName = product.LogoInfo[p].extra_logo[e].location
                        getIndex = i
                        break;
                      }

                    }
                  }
                }
                break;
              }
            }
          }
          break;
        }
      }
    }

    if (locationName !== null && locationName !== 'undefined') {
      let getselectedLocation = catalogueCustomRows[getIndex][0].decorations.filter((val) => val.location === locationName)
      setSelectedDecoration(getselectedLocation[0])
      setTempValue(tempValue + 1)
    }

  }
  const thumbnailSwiperParams = {

    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
    on: {
      init: () => {
      },
      slideChangeTransitionEnd: (el) => {
        if (document.querySelector('#sw1')) {
          if (
              document.querySelector('#sw1').querySelector('.swiper-slide-active')
          ) {
            if (
                document
                    .querySelector('#sw1')
                    .querySelector('.swiper-slide-active')
                    .querySelector('span')
            ) {
              let color_current = document
                  .querySelector('#sw1')
                  .querySelector('.swiper-slide-active')
                  .querySelector('span').innerText
              setSelectedProductColor(color_current)
              let color_image_url = document
                  .querySelector('#sw1')
                  .querySelector('.swiper-slide-active')
                  .querySelector('img').src

              // getDecoAttach(color_current, color_image_url)
            }
          }
        }
      },
    },
    getSwiper: getThumbnailSwiper,
    spaceBetween: 20,
    slidesPerView: 1,
    loopedSlides: 4,
    touchRatio: 0.2,
    freeMode: false,
    loop: product?.MediaContent?.length <= 1 ? false : true,
    rebuildOnUpdate: false,
    slideToClickedSlide: false,
  }
  const addFirstRow = () => {
    let newRow = [
      {
        id: uniqIdcounter++,
        size: '',
        quantity: '',
        image: '',
        color: '',
        product: {sku: 0},
        price: 0,
        totalPrice: 0,
      },
    ]
    let tempRows = catalogueCustomRows
    tempRows.push(newRow)

    //add first row
    setCatalogueCustomRows(tempRows)

    updatestate(!statchange)
  }
  const MainSize = ['XS', 'S', 'M', 'L', 'XL', '1XL', '2XL', '3XL']
  let groups = []
  let colors = []
  let colors_final = []
  let colors_ids = []
  let colors_idsColor = []
  let colors_hex = []
  let priceTable = []
  let priceTableTemp = []
  let show_color = true
  if (product?.ProductPartArray?.ProductPart?.length) {
    let tempOptions = product.ProductPartArray.ProductPart
    if (product?.WebstoreProductPartArray?.length > 0) {
      tempOptions = product.WebstoreProductPartArray
    }
    tempOptions.forEach((part) => {
      let priceOption = part
      if (part?.ApparelSize?.labelSize) {
        const size = part.ApparelSize.labelSize
        if (!groups[size]) {
          groups[size] = []
        }
        groups[size].push(priceOption)

        const color = part.ColorArray.Color.colorName
        colors_ids[part.partPrice.partId] = color
        colors_idsColor[color] = part.partPrice.partId
        colors_hex[color] = part.ColorArray.Color.hex
        if (!part.ColorArray.Color.hex || part.ColorArray.Color.hex === '') {
          show_color = false
        }
        if (!colors[color]) {
          colors[color] = []
        }
      }
    })
  }
  for (let size in groups) {
    priceTableTemp.push({size: size, priceOptions: groups[size]})
  }

  priceTableTemp = priceTableTemp.sort((a,b) => {
    return defaultSizeForAll.indexOf(a.size.toLocaleLowerCase()) - defaultSizeForAll.indexOf(b.size.toLocaleLowerCase());
  })
  let priceTableTemp1 = []
  let priceTableTemp2 = []

  priceTableTemp.map((a) => {
    if(defaultSizeForAll.indexOf(a.size.toLocaleLowerCase()) !== -1) {
      priceTableTemp1.push(a)
    } else {
      priceTableTemp2.push(a)
    }
  })

  if(priceTableTemp2.length > 0) {
    priceTableTemp1 = priceTableTemp1.concat(priceTableTemp2)
  }
  
  priceTableTemp1.map(res => {
    priceTable.push(res)
  })
  for (let color in colors) {
    colors_final.push(color)
  }
  const numericSort = (a, b) => {
		const aNumeric = parseInt(a);
		const bNumeric = parseInt(b);
		return aNumeric - bNumeric;
	};
	colors_final = colors_final.sort(numericSort);
  let tempCount = 0
  const [tempValueCheck, setTempValueCheck] = useState(0)
  const colorselectbox = (quoteItemRow, selectedValue, mainKey) => {
    if(colors_final.length === 1 && tempValueCheck === 0) {
      verifyShowColor()
      selectedValue = colors_final[0]
      changeColor(colors_final[0], quoteItemRow, mainKey)
      setTempValueCheck(tempValueCheck+1)
    }
    if (colors_final.length) {
      return (
        <div className="col-md-12 pl-0 pr-0 pt-1">
          <select
              data-testid = "combo-box-clor-plw"
              style={{width: '100%', 'marginLeft': '2px'}}
              value={selectedValue}
              onChange={(e) => changeColor(e, quoteItemRow, mainKey)}
          >
            <option key={'colorkey'}>Select {colorLabel}</option>
            {colors_final.map((color, key) => {
              return <option value={color}>{color}</option>
            })}
          </select>
        </div>
      )
    }
  }

  useEffect(() => {
    if (product) {
      if (product.WebstoreProductPartArray?.length) {
        const partPriceArray = product.WebstoreProductPartArray.find(x =>
          x.active &&
          tempSelectedColor && tempSelectedColor !== colorLabel ? x.color === tempSelectedColor : x.color === colors_final[0]
        );
        if (partPriceArray) {
          const priceTable = partPriceArray.partPrice?.PartPriceArray?.PartPrice ?? []
          setPriceTable(priceTable)
        }
      } else if (product?.ProductPartArray?.ProductPart && product?.ProductPartArray?.ProductPart.length > 0) {
        const defaultColor = colors_final?.[0] || '';
        const selectedColorToCompare = tempSelectedColor || defaultColor;
        const part = product.ProductPartArray.ProductPart.find(part => {
          const colors = part?.ColorArray?.Color?.colorName ?? part.color;
          return selectedColorToCompare.toLowerCase() === colors.toLowerCase();
        });
        if (part) {
          const priceTable = part.partPrice?.PartPriceArray?.PartPrice ?? [];
          setPriceTable(priceTable);
          
        }
      }
    }
  }, [product, tempSelectedColor])

  useEffect(() => {
    if (artworksProductState?.length && colors_final.length === 1 && tempValueCheck === 1) {
      verifyShowColor()
      changeColor(colors_final[0], {}, 0)
      setTempValueCheck(tempValueCheck + 1)
    }
  }, [artworksProductState])

  const checkPriceOptionsArray = (
      priceoption,
      itemRowArraycolor,
      pricerowsize,
  ) => {
    let selectDefaultColor = (showColor === false ? priceoption?.ColorArray?.Color?.colorName === itemRowArraycolor : true)
    if (
        priceoption?.ApparelSize?.labelSize &&
        priceoption.ApparelSize.labelSize === pricerowsize &&
        pricerowsize !== '' && selectDefaultColor
    ) {
      if (
          (priceoption?.ColorArray?.Color?.colorName &&
              priceoption.ColorArray.Color.colorName === itemRowArraycolor &&
              itemRowArraycolor !== '')
      ) {
        if (priceoption?.partPrice?.PartPriceArray) {
          return true
        }
      }
    }
    return false
  }
  //when select location for decoration
  const handleCheckboxDefault = (locationId, arrayIndex) => {
    //setNotCallDecoHtml(true)
    setAutoSelectedLocation(locationId)
    const requiredDecoration = autoAttachDecoration(locationId)
    if (!requiredDecoration) {
      return
    }
    setSelectedLocationIndex(arrayIndex)
    let tempRows = catalogueCustomRows.filter((rowGroupArray, groupkey) => {
      if (groupkey === arrayIndex) {
        let locationName = decorationLocations?.find((locationFilter) => locationFilter.id === locationId)?.locationName
        setSelectedLocationName(locationName)
        setSelectedLocation(locationId)
        temp(arrayIndex, '', locationName)
        rowGroupArray.forEach((rowArray) => {
          if (rowArray.decorations) {
            let findIfexist = rowArray.decorations.filter(
                (decorationArray) => decorationArray.locationId === locationId,
            )
            if (locationId !== '') {
              if (findIfexist?.length === 0) {
                let tempaddDeco = {
                  location: locationName,
                  locationId: locationId,
                }
                rowArray.decorations.push(tempaddDeco)
                setSelectedDecoration(tempaddDeco)
              }
            }
          } else {
            rowArray['decorations'] = []
            let tempaddDeco = {
              location: locationName,
              locationId: locationId,
            }
            setSelectedDecoration(tempaddDeco)

            rowArray.decorations.push(tempaddDeco)
          }
          return rowArray
        })
      }
      return rowGroupArray
    })

    //when single location of decoration
    setCatalogueCustomRows(tempRows)
  }
  //when we have multiple location for decoration
  const handleCheckbox = (e, arrayIndex, decorationId = '', allowOneDecq="") => {

    let selectedLocationId = e?.target?.value ? e.target.value.slice(0, -2) : e

    setSelectedLocationIndex(arrayIndex)
    setIsAdded(false)
    let tempRows = catalogueCustomRows.filter((rowGroupArray, groupkey) => {
      if (groupkey === arrayIndex) {
        let locaName = decorationLocations?.filter(
            (locationFilter) => locationFilter.id === selectedLocationId,
        )[0]?.locationName

        setSelectedLocationName(locaName)
        setSelectedLocation(selectedLocationId)
        temp(arrayIndex, '', locaName)

        rowGroupArray.filter((rowArray) => {
          if (rowArray.decorations?.length > 0 && allowOneDecq === "2") {
            let checkLocation = rowArray.decorations && rowArray.decorations.find((d) => !!d?.locationId && !!d?.variationUniqID)
            if(!!checkLocation && checkLocation?.locationId !== selectedLocationId) {
              rowArray.decorations = []
            }
          }

          if (rowArray.decorations?.length > 0) {
            let findIfexist = rowArray.decorations.filter(
                (decorationArray) => decorationArray.locationId === selectedLocationId,
            )
            if (selectedLocationId !== '') {
              if (findIfexist?.length === 0) {
                let tempaddDeco = {
                  location: locaName,
                  locationId: selectedLocationId,
                }
                if(!!locaName) {
                  rowArray.decorations.push(tempaddDeco)
                  setSelectedDecoration(tempaddDeco)
                } else {
                  rowArray.decorations = rowArray.decorations.filter(x => !!x.artworkID);
                }
              }
            } 
          } else {
            rowArray['decorations'] = []
            let tempaddDeco = {
              location: locaName,
              locationId: selectedLocationId,
            }
            if(!!locaName) {
              setSelectedDecoration(tempaddDeco)
              rowArray.decorations.push(tempaddDeco)
            } else {
              rowArray.decorations = rowArray.decorations.filter(x => !!x.artworkID);
            }
          }
          return rowArray
        })
      }
      return rowGroupArray
    })

    //when we have multiple location for decoration
    setCatalogueCustomRows(tempRows)

  }
  useEffect(() => {
    if (product?.AllowOutofStock === true || product?.AllowOutofStock === false) {
      setIsAllowOutOfStockOrder(product?.AllowOutofStock)
    } else {
      setIsAllowOutOfStockOrder(isAllowoutofStockOrder)
    }
    if(product) {
      setIsProductInventoryEnabled(getInventorySettingStatus(product))
    }
  }, [product, isAllowoutofStockOrder])
  const changeQty = (e, mainArraykey, rowSize, callFrm = 0, colorVal = '') => {
    let quantityFromCart = 0;
    if(rowSize && colorVal) {
      quantityFromCart = getCartItemTotal(product.productId, colorVal, rowSize);
    }
   if(callFrm === 0) {
    e.target.placeholder = ''
    const regularExpression = /^[0-9\b]+$/
    if (e.target.value !== '' && !regularExpression.test(e.target.value)) {
      return false;
    }
    if (!Number(e.target.value) && e.target.value !== '') {
      return false;
    }
   }
    if (product.showColorProd && (!tempSelectedColor || tempSelectedColor === `Select ${colorLabel}`) && callFrm === 0) {
      e.target.value = '';
      return false;
    }
    let tempRows = catalogueCustomRows.filter((rowGroupArray, groupkey) => {
      if (groupkey === mainArraykey) {
        let checkEditRowexist = rowGroupArray.filter(
            (rowArray) => rowArray.size === rowSize,
        )
        if (checkEditRowexist?.length > 0) {
          rowGroupArray.filter((rowArray) => {
            if (rowArray.size === rowSize) {
              rowArray.quantity = callFrm === 1 ? e : e.target.value
              priceTable.map((priceTableRow) => {
                if (priceTableRow.size === rowArray.size) {
                  let priceOptionsArray = priceTableRow.priceOptions
                  priceOptionsArray.map((priceOption) => {
                    if (
                        priceOption.ColorArray.Color.colorName === rowArray.color
                    ) {
                      let qtStock = getStock(rowArray.size, rowArray.color, rowArray?.partId)
                      let totalToCompare = (qtStock - quantityFromCart) < 0 ? 0 : qtStock - quantityFromCart 
                      if (IsAllowOutOfStockOrder) {
                      } else if (totalToCompare < rowArray.quantity) {
                        rowArray.quantity = totalToCompare;
                      }
                      if (!rowArray.product) {
                        rowArray['product'] = {}
                      }
                      rowArray.product.sku = priceOption.sku
                      if (
                          priceOption.partPrice.PartPriceArray.PartPrice.length > 0
                      ) {
                        /*let priceRow = priceOption.partPrice.PartPriceArray.PartPrice[0]
                        const minQuantityRow = priceOption.partPrice.PartPriceArray.PartPrice.filter(x =>
                            Number(x.minQuantity) <= Number(rowArray.quantity))
                        priceRow = minQuantityRow.length > 0 ?
                            minQuantityRow.reduce((prev, current) =>
                                (Number(prev?.salePrice) < Number(current.salePrice)) ? prev : current
                            ) : priceRow
                        rowArray.price = priceRow.salePrice
                        rowArray.cost = priceRow.price*/
                        const quantitys = Number(rowArray.quantity);
                        priceOption.partPrice.PartPriceArray.PartPrice.map(
                          (partPrice, k) => {
                            if (
                              quantitys !== '' &&
                              Number(quantitys) > 0 &&
                              Number(quantitys) >= Number(partPrice.minQuantity)
                            ) {
                              rowArray.price = partPrice.salePrice
                              rowArray.cost = partPrice.price
                            } else if (k === 0) {
                              rowArray.price = partPrice.salePrice
                              rowArray.cost = partPrice.price
                            }
                          },
                        )
                      }

                      if (Number(userGroupDiscount > 0) && Number(rowArray.price) > 0) {
                        rowArray.price =
                            Number(rowArray.price) -
                            (Number(rowArray.price) * Number(userGroupDiscount)) /
                            100
                      }

                      if (discountType === '1' && showSell && Number(rowArray.price) > 0) {
                        if (offerPrice > 0) {
                          rowArray.price =
                              Number(rowArray.price) - Number(offerPrice)
                        }
                      } else if (discountType === '0' && showSell && Number(rowArray.price) > 0) {
                        if (offerPrice > 0) {
                          rowArray.price =
                              Number(rowArray.price) -
                              (Number(rowArray.price) * Number(offerPrice)) / 100
                        }
                      }

                      rowArray.totalPrice = rowArray.price * rowArray.quantity
                      rowArray.totalCost = rowArray.cost * rowArray.quantity
                    }
                  })
                }
              })
            }
            return rowArray
          })
        } else {
          if (rowGroupArray[0]) {
            if (rowGroupArray[0].size === '') {
              rowGroupArray[0].size = rowSize
              rowGroupArray[0].quantity = callFrm === 1 ? e : e.target.value
              if (!product.showColorProd && colors_final.length === 1) {
                rowGroupArray[0].color = colors_final[0];
              }
              let tempGroupArray = {...rowGroupArray[0]}
              priceTable.map((priceTableRow) => {
                if (priceTableRow.size === tempGroupArray.size) {
                  let priceOptionsArray = priceTableRow.priceOptions
                  priceOptionsArray.map((priceOption) => {
                    if (
                        priceOption.ColorArray.Color.colorName ==
                        tempGroupArray.color
                    ) {
                      if (!tempGroupArray.product) {
                        tempGroupArray['product'] = {}
                      }
                      tempGroupArray.product.sku = priceOption.sku
                      if (
                          priceOption.partPrice.PartPriceArray.PartPrice.length > 0
                      ) {
                        priceOption.partPrice.PartPriceArray.PartPrice.map(
                            (p, pKey) => {
                              tempGroupArray.price = p.salePrice;
                              tempGroupArray.cost = p.price;
                            },
                        )
                      }
                      tempGroupArray.totalPrice =
                          tempGroupArray.price * tempGroupArray.quantity
                      tempGroupArray.totalCost =
                          tempGroupArray.cost * tempGroupArray.quantity
                    }
                  })
                }
              })
              rowGroupArray[0] = tempGroupArray
            } else {
              let newRow = {
                id: uniqIdcounter++,
                size: rowSize,
                quantity: callFrm === 1 ? e : e.target.value,
                image: rowGroupArray[0].image,
                color: rowGroupArray[0].color,
                decorations: rowGroupArray[0].decorations,
                product: {sku: 0},
                price: 0,
                totalPrice: 0,
              }
              priceTable.map((priceTableRow) => {
                if (priceTableRow.size === newRow.size) {
                  let priceOptionsArray = priceTableRow.priceOptions
                  priceOptionsArray.map((priceOption) => {
                    if (
                        priceOption.ColorArray.Color.colorName === newRow.color
                    ) {
                      newRow.product.sku = priceOption.sku
                      if (
                          priceOption.partPrice.PartPriceArray.PartPrice.length > 0
                      ) {
                        /*let priceRow = priceOption.partPrice.PartPriceArray.PartPrice[0]
                        const minQuantityRow = priceOption.partPrice.PartPriceArray.PartPrice.filter(x =>
                            Number(x.minQuantity) <= Number(newRow.quantity))
                        priceRow = minQuantityRow.length > 0 ?
                            minQuantityRow.reduce((prev, current) =>
                                (Number(prev?.salePrice) < Number(current.salePrice)) ? prev : current
                            ) : priceRow
                        newRow.price = priceRow.salePrice
                        newRow.cost = priceRow.price*/
                        priceOption.partPrice.PartPriceArray.PartPrice.map(
                          (partPrice, k) => {
                            if (
                              newRow.quantity !== '' &&
                              Number(newRow.quantity) > 0 &&
                              Number(newRow.quantity) >= Number(partPrice.minQuantity)
                            ) {
                              newRow.price = partPrice.salePrice
                              newRow.cost = partPrice.price
                            } else if (k === 0) {
                              newRow.price = partPrice.salePrice
                              newRow.cost = partPrice.price
                            }
                          },
                        )
                      }
                      newRow.totalPrice = newRow.price * newRow.quantity
                      newRow.totalCost = newRow.cost * newRow.quantity
                    }
                  })
                }
              })
              rowGroupArray.push(newRow)
            }
          }
        }
      }
      return rowGroupArray
    })
    if (isPriceBreak) {
      let totalSum = 0;
      tempRows.map((object, index) => {
        object.map((sizeObj, index) => {
          totalSum = totalSum + Number(sizeObj.quantity)
        })
      })
      if (totalSum > 0) {
        let priceArray = []
        let obj1;
        tempRows.map((catItem, index) => {
          let productPartArray = product?.WebstoreProductPartArray && product?.WebstoreProductPartArray.length > 0 ? product?.WebstoreProductPartArray : product?.ProductPartArray?.ProductPart
          catItem.map((obj, index) => {
            obj1 = productPartArray.find((productPart) => productPart.ColorArray?.Color?.colorName === obj.color && productPart.ApparelSize?.labelSize === obj.size)
            let rPrice = 0
            let rCost = 0
            if(obj1?.ApparelSize?.labelSize === obj?.size && obj?.color === obj1?.ColorArray?.Color?.colorName) {
              obj1.partPrice.PartPriceArray.PartPrice.map(
                (partPrice, k) => {
                  if (
                      Number(partPrice.minQuantity) <= Number(totalSum)
                  ) {
                    rPrice = partPrice.salePrice
                    rCost = partPrice.price
                  } else if (k === 0) {
                    rPrice = partPrice.salePrice
                    rCost = partPrice.price
                  }
                },
              )
              obj.price = rPrice
              obj.cost = rCost
            }
          })
          // priceArray.push(obj1)
        })
        /*let minQuantity = []
        priceArray.map((price) => {
          let min = price?.partPrice?.PartPriceArray?.PartPrice.filter(
              (val) => Number(val.minQuantity) <= Number(totalSum)
          )
          if (min && min.length > 0) {
            minQuantity.push(min)
          }
        })
        let allMaxQty = [];
        minQuantity.length > 0 && minQuantity.map((qtyMin) => {
          let maxQty = qtyMin.length > 0 && qtyMin.reduce((prev, current) => (Number(prev?.minQuantity) > Number(current.minQuantity)) ? prev : current)
          allMaxQty.push(maxQty)
        })
        let salePrice = allMaxQty.length > 0 && allMaxQty.reduce((prev, current) => (Number(prev?.salePrice) > Number(current.salePrice)) ? prev : current)
        tempRows.map((cartItem, index) => {
          cartItem.map((order, index) => {
            order.price = (salePrice?.salePrice && Number(salePrice?.salePrice) !== 0) ? Number(salePrice?.salePrice) : Number(order.price)
            order = order
          })
        });*/
      }
    }

    //call when change qty
    setCatalogueCustomRows(tempRows)
    if (personalizationAdded) {
      let totalQuantity = tempRows[mainArraykey]?.reduce((acc, val) => acc + Number(val.quantity), 0) ?? 0;
      if (totalQuantity > 0) {
        ToggleDivs(mainArraykey, 'P', 'changeQty')
      } else {
        ToggleDivs(mainArraykey, 'P', 'changeQtyZero')
      }
      addPersonalization(mainArraykey)
    }
  }

  const handleArtworkselection = async (
      artwork,
      parentKey,
      locationID,
      findProductArtvariationId,
      selectDeco,
      isRequiredForStore,
      supplierDeco
  ) => {

    let tempcatalogueCustomRows = await catalogueCustomRows.filter(
        async (rowGroupArray, groupkey) => {
          if (groupkey === parentKey) {
            await rowGroupArray.filter(async (rowArray) => {
              rowArray.decorations &&
              await rowArray.decorations.map(async (decoFilter) => {
                if (decoFilter.locationId === locationID) {
                  decoFilter.artworkID = artwork.id
                  decoFilter.isRequiredForStore = isRequiredForStore
                  decoFilter.supplierDeco = supplierDeco
                  decoFilter.variationUniqID = null
                  if (artwork.design && artwork.design.variation) {
                    if (artwork.design.variation.length) {
                      if (artwork.design.variation.length > 0) {
                        let getSelectedVaria = artwork.design.variation.filter(
                            (val) =>
                                val.design_variation_unique_id ==
                                findProductArtvariationId
                        )
                        if (getSelectedVaria) {
                          decoFilter.variationUniqID =
                              getSelectedVaria[0].design_variation_unique_id
                        } else {
                          decoFilter.variationUniqID =
                              artwork.design.variation[0].design_variation_unique_id
                        }
                      }
                      await select_variant(
                          artwork.id,
                          parentKey,
                          findProductArtvariationId,
                          locationID,
                          selectDeco
                      )
                    }
                  }
                }
                return decoFilter
              })
              return rowArray
            })
          }
          return rowGroupArray
        },
    )
    //click on handle art work
    setCatalogueCustomRows(tempcatalogueCustomRows)
  }

  const select_variant = async (
      artWorkID,
      parentKey,
      findProductArtvariationId,
      locationID,
      selectDeco
  ) => {

    let artwork_selected = null
    if (artWorkID) {
      artwork_selected =
        artworksFromStateAll.length && artworksFromStateAll.filter((f) => f.id === artWorkID)[0]

      if (artwork_selected.design && artwork_selected.design.variation) {
        if (artwork_selected.design.variation.length) {
          let artData = {}
          if (artwork_selected.design.variation.length > 0) {
            let getSelectedVaria = artwork_selected.design.variation.filter(
                (val) =>
                    val.design_variation_unique_id == findProductArtvariationId,
            )

            if (getSelectedVaria) {
              artData = getSelectedVaria[0]
            } else {
              artData = artwork_selected.design.variation[0]
            }
          }

          await handleVariationselection(
              parentKey,
              locationID,
              artData,
              artwork_selected,
              selectDeco
          )
        }
      }
    }
  }

  const handleVariationselection = async (
      parentKey,
      locationID,
      artwork,
      artwork_selected,
      selectDeco
  ) => {

    let dPrice = await getDecoPrice(artwork_selected.id)

    let decoPricedetails = []
    if (dPrice?.length > 0) {
      dPrice.map((priceArray) => {
        if (artwork_selected?.design?.designType === priceArray.decoratorType) {
          decoPricedetails.push(priceArray)
        }
      })
    }

    let tempcatalogueCustomRows = await catalogueCustomRows.filter(
        (rowGroupArray, groupkey) => {
          if (groupkey === parentKey) {
            rowGroupArray.filter((rowArray) => {
              rowArray.decorations &&
              rowArray.decorations.map((decoFilter) => {
                if (decoFilter.locationId === locationID) {
                  if (decoPricedetails) {
                    decoFilter.decoPricedetails = decoPricedetails
                  }
                  decoFilter.variationUniqID =
                      artwork.design_variation_unique_id
                }
                return decoFilter
              })
              return rowArray
            })
          }
          return rowGroupArray
        },
    )
    if (selectDeco) {
      getDisplayStyle(parentKey, 'D')

      let currentProduct = catalogueCustomRows[parentKey][0]
      let productColor = catalogueCustomRows[parentKey][0].color
      let decoData = currentProduct?.decorations?.filter(
          (val) => val.artworkID === artwork_selected.id,
      )

      let decoLocation = (decoData || []).find(x => x.locationId === locationID)?.location
      let getIndex = product.LogoInfo.filter(
          (val) =>
              val.color === productColor &&
              val.location === decoLocation,
      )

      if (getIndex.length > 0) {
        animateScroll.scrollToTop()
        setSelectedImage(getIndex[0]?.image)
        setIsSelectedImageChng(IsSelectedImageChng+1)
      } else {

        let getIndexOfExtra_logo = []
        product.LogoInfo.map(val => {
          if (val.color === productColor) {

            if (val.extra_logo.filter(e => e.location === decoLocation).length > 0) {
              getIndexOfExtra_logo.push(val)
            }
          }
        })
        if (getIndexOfExtra_logo.length) {

          animateScroll.scrollToTop()
          setSelectedImage(getIndexOfExtra_logo[0]?.image)
          setIsSelectedImageChng(IsSelectedImageChng+1)
        } else {
          let getImgColorIndex = product.LogoInfo.filter(
              (val) =>
                  val.color === productColor
          )

          if (getImgColorIndex.length > 0) {
            animateScroll.scrollToTop()
            setSelectedImage(getImgColorIndex[0]?.image)
            setIsSelectedImageChng(IsSelectedImageChng+1)
          }
        }
      }
      //art variation
      setCatalogueCustomRows(tempcatalogueCustomRows)
    }

  }
  const changeColor = async (e, quoteItemRow, mainKey) => {
    setAutoSelectedLocation('')
    ToggleDivs(mainKey, 'D', 'onChangeColor')
    setSelectedLocationIndex(mainKey)
    setSelectedImage('')
    let color =
        e && e.target && e.target.value && e.target.value ? e.target.value : e

    setTempSelectedColor(color)
    setSelectedProductColor(color);
    let getImage = product?.WebstoreProductPartArray.filter((val) => val.color == color && val.isSwapImage === true,)


    let tempRows = catalogueCustomRows.filter((rowGroupArray, groupkey) => {
      if (groupkey === mainKey) {
        rowGroupArray.filter((rowArray) => {
          rowArray.color = color
          if (product.MediaContent?.length > 0) {
            let flag = true
            let defaultImages = product.MediaContent.filter(
                (val) => val.color === rowArray.color && val.primary,
            )
            if (defaultImages.length) {
              rowArray.image = defaultImages[0].url
            } else {
              let secondaryimages = product.MediaContent.filter(
                  (val) => val.color === rowArray.color,
              )
              if (secondaryimages.length) {
                rowArray.image = secondaryimages[0].url
              }
            }
          }
          return rowArray
        })
      }
      return rowGroupArray
    })
    tempRows[mainKey][0]['decorations'] = []
    if (getImage.length === 0) {
      if (getSettingStatus('showDecoration') || !getSettingStatus('showDecoration')) {
        setIsAdded(false)
        let findProductArtvariationId = ''
        let locationId = ''
        const numOfArtwork = artworksFromStateAll.reduce((acc, val) => acc +
                (val.productToDecorationMapping || []).filter(x => x.isRequiredForStore === '1' && x.mapping.filter(m => m.color === color)).length, 0
        )
        if (artworksFromStateAll && artworksFromStateAll.length) {
          let keyCount = 0
          artworksFromStateAll.map((val, key) => {
            (val?.productToDecorationMapping || []).forEach((decoMap) => {
              if (decoMap?.isRequiredForStore === '1') {
                locationId = decoMap.location
                decoMap.mapping.map(async (mapVal) => {
                  if (mapVal.color === color) {
                    handleCheckbox(locationId, mainKey)
                    findProductArtvariationId = mapVal.variationId
                    let isRequiredForStore = decoMap.isRequiredForStore
                    let supplierDeco = decoMap.supplierDeco
                    await handleArtworkselection(
                        val,
                        mainKey,
                        locationId,
                        findProductArtvariationId,
                        keyCount++ === (numOfArtwork - 1),
                        isRequiredForStore,
                        supplierDeco
                    )
                  }
                })
              } else {
                setShowData('yes')
              }
            })
          })
        }
      }
      setIsReplace(true)
    } else {
      setIsReplace(false)
    }

    //change color
    let Quantities = document.getElementsByName('quantity');
    if(!!Quantities) {
      if(!!Quantities.length && Quantities.length > 0) {
        Quantities.forEach((element, key) => {
          if(element.value !== '') {
            element.focus()
          }
        });
      }
    }

    setCatalogueCustomRows(tempRows)
    setTempValue(tempValue + 1)
    if (personalizationAdded) {
      let totalQuantity = catalogueCustomRows[mainKey]?.reduce((acc, val) => acc + Number(val.quantity), 0) ?? 0;
      if (totalQuantity > 0) {
        ToggleDivs(mainKey, 'P', 'onChangeColor')
      }
			addPersonalization(mainKey)
    }
  }

  const deleteRow = (rowkey) => {
    let tempRows = catalogueCustomRows.filter(
        (rowGroup, rowGroupkey) => rowkey !== rowGroupkey,
    )

    //delete row

    setCatalogueCustomRows(tempRows)
    updatestate(!statchange)
  }
  const ToggleDivs = (RowID, Type, extraParm = '') => {
    let temptoggleDivsArray = toggleDivsArray
    if (!temptoggleDivsArray[RowID]) {
      temptoggleDivsArray[RowID] = []
    }
    if (temptoggleDivsArray[RowID]) {
      let rowCurrentState = temptoggleDivsArray[RowID]
      if (Type === 'D') {
        rowCurrentState.attributes = false
        rowCurrentState.personalization = false

        if (rowCurrentState.decorations && (extraParm !== 'onChangeColor' && extraParm !== 'clickOnArtWork')) {

          //rowCurrentState.decorations = false
        } else {
          setShowData('yes')
          // rowCurrentState.decorations = true
          setNotCallDecoHtml(false)
        }
        temptoggleDivsArray[RowID] = rowCurrentState
      }
      rowCurrentState.decorations = true
      if (Type === 'A') {
        rowCurrentState.decorations = false
        rowCurrentState.personalization = false
        if (rowCurrentState.attributes) {
          rowCurrentState.attributes = false
        } else {
          rowCurrentState.attributes = true
        }
        temptoggleDivsArray[RowID] = rowCurrentState
      }
      if (Type === 'P') {
        if (extraParm !== 'changeQtyZero') {
          rowCurrentState.decorations = false
          rowCurrentState.attributes = false
        }
        if (rowCurrentState.personalization) {
         if(extraParm !== 'onChangeColor' && extraParm !== 'onCopyRow' && extraParm !== 'changeQty') {
           rowCurrentState.personalization = false
         }
         else {
           rowCurrentState.personalization = true
         }
        } else {
          rowCurrentState.personalization = true
        }
        if(extraParm === 'DeleteRow' || extraParm === 'changeQtyZero') {
          rowCurrentState.personalization = false
        }
        if(extraParm === 'ForOnCopyRow') {
          rowCurrentState.personalization = true
        }
        temptoggleDivsArray[RowID] = rowCurrentState
      }
    }

    setToggleDivs(temptoggleDivsArray)
    setToggleValue(!toggleValue)
  }
  const getDisplayStyle = (key, Type) => {
    if (toggleDivsArray[key]) {
      if (Type === 'D') {
        if (toggleDivsArray[key].decorations) {
          return 'block'
        } else {
          return 'none'
        }
      }
      if (Type === 'A') {
        if (toggleDivsArray[key].attributes) {
          return 'block'
        } else {
          return 'none'
        }
      }
      if (Type === 'P') {
        if (toggleDivsArray[key].personalization && personalizationAdded) {
          return 'block'
        } else {
          return 'none'
        }
      }
      return 'none'
    } else {
      return 'none'
    }
  }
  const copyRow = (rowkey) => {
    let findRow = null
    let tempRows = [...catalogueCustomRows]
    let Main = []
    tempRows.map((rowGroup, rowGroupkey) => {
      Main.push(JSON.parse(JSON.stringify(rowGroup)))
      if (rowGroupkey === rowkey && !findRow) {
        if (rowGroup) {
          let tempObj = []
          rowGroup.map(obj => {
            obj.id = uniqIdcounter++;
            if(!!obj?.personalization) {
              obj.personalization.map(perArra => {
                perArra.rowId = obj.id
                return perArra
              })
            }
            obj.extraParam = 'onCopyRow'; tempObj.push(obj) })
            Main.push(JSON.parse(JSON.stringify(tempObj)))
        }
      }
    })

    // copy row
    /*setCatalogueCustomRows(tempRows)
    if (personalizationAdded) {
      addPersonalization()
    }*/
    if (personalizationAdded) {
      let maincnt = Number(Main.length - 1)
      ToggleDivs(maincnt, 'P', 'onCopyRow')
      addPersonalization(maincnt, 'onCopyRow')
    }

    setCatalogueCustomRows(Main)
    updatestate(!statchange)
  }


  const selectDeco = (getIndex, itemRowArray, decoitem, mainIdex) => {
      setSwipeimageFlag(false)
      setSelectedLocationIndex(mainIdex)
      setTempSelectedColor(itemRowArray.color)
      setCurrentActiveId(itemRowArray.id)
      getIndex = product.LogoInfo.filter(
          (val) =>
              val.color === itemRowArray.color &&
              val.location === decoitem.location,
      )
      setSelectedLocation(decoitem.locationId)
      setSelectedDecoration(decoitem)

      if (getIndex.length > 0) {
        setSwipeimageFlag(true)
        setSelectedImage(getIndex.length > 0 ? getIndex[0].image : [])
        setIsSelectedImageChng(IsSelectedImageChng+1)
        // animateScroll.scrollToTop()
      } else {
        getIndex = product.LogoInfo.filter(t => t.color === itemRowArray.color && t.extra_logo.filter(y => y.location === decoitem.location).length > 0);
        if (getIndex.length > 0) {
          setSelectedImage(getIndex[0]?.image)
          setIsSelectedImageChng(IsSelectedImageChng+1)
          setSwipeimageFlag(true)
        }
      }
      setImageScroll(imageScroll+1)
  }

  const checkTaotalQty = () => {
    let totalSumqw = 0
    catalogueCustomRows.map((itemsRowsabs, keya) => { itemsRowsabs && itemsRowsabs.length > 0 && itemsRowsabs.map((sizeObj, index) => {
        totalSumqw = totalSumqw + Number(sizeObj?.quantity && sizeObj?.quantity)
      })
    })
    return totalSumqw;
  }
  let toatlQty = 0
  let toatlPrice = 0
  let totalCost = 0
  let totalStock = 0

  const getRowHTML = (itemsRows, key) => {
    let itemRowArray = itemsRows[0]
    let rowTotalQty = '0'
    let rowTotal = 0
    let minIsPriceBreak;
    let totalSum = 0
    let getImage = product?.WebstoreProductPartArray.filter((val) => val.color === itemRowArray.color && val.isSwapImage === true,)
    const artWorkCount = document.querySelectorAll(`[findparent=parent${key}]`)

    let artwork_selected = null
    if (artworksFromStateAll?.length && itemRowArray?.decorations?.length) {
      itemRowArray.decorations.filter((de)=>de?.artworkID && de?.location && de?.locationId).map((decoArray, index) => {
        artwork_selected = artworksFromStateAll.filter(
          (artorkArray) =>
          artorkArray.id === decoArray.artworkID && artorkArray.artworkLocation === decoArray.location,
      )[0]
      if(!!!artwork_selected && artworksProductState?.length) {
          artwork_selected = artworksProductState.find((artworkArray) =>
            artworkArray?.productToDecorationMapping?.some(
              (res) => res.locationName === decoArray.location && artworkArray.id === decoArray.artworkID
            )
          );
        }
      })
    }


    if (itemRowArray?.decorations?.length >= 1) {
      let decoIndex = itemRowArray?.decorations?.length - 1
      let getColorIndex = product.LogoInfo.filter(
          (val) => val.color === tempSelectedColor,
      )

      let getIndex = []
      if (getColorIndex.length > 1) {
        getIndex = product.LogoInfo.filter(
            (val) =>
                val.color === tempSelectedColor && !val?.primary &&
                (val.location === itemRowArray.decorations[decoIndex].location || val.extra_logo.filter(e => e.location === itemRowArray.decorations[decoIndex].location)),
        )
      } else {
        getIndex = getColorIndex
      }

      if (getIndex.length > 0) {
        if (selectedImage === '') {
          setSelectedImage(getIndex[0].image)
          setIsSelectedImageChng(IsSelectedImageChng+1)
        }
      }
    } else {
      //first we need to check image have primary or not. if yes then use primary image else get default first image
      if (getImage.length > 0) {
        if (selectedImage === '') {
          setSelectedImage(getImage[0]?.image)
          setIsSelectedImageChng(IsSelectedImageChng+1)
        }
      } else {
        let getIndex = product.MediaContent.filter((val) => val.color === itemRowArray.color && val.primary && !val?.hidden,
        )
        if (getIndex.length) {
          if (selectedImage === '') {
            setSelectedImage(getIndex[0].url)
            setIsSelectedImageChng(IsSelectedImageChng+1)
          }
        } else {
          if (selectedImage === '') {
            let secondarygetIndex = product.MediaContent.filter(
                (val) => val.color === itemRowArray.color && !val?.hidden,
            )
            setSelectedImage(secondarygetIndex[0]?.url)
            setIsSelectedImageChng(IsSelectedImageChng+1)
          }
        }
      }
    }


    let addDecoPriceFlag = true
    let totalSetupDecoCharges = 0
    let totalDecoCharges = 0
    let totalSetupCharge = 0
    let setupCharges = [];
    let setupChargeName = 'Setup Charge'
    if (isPriceBreak) {
      itemsRows && itemsRows.length > 0 && itemsRows.map((sizeObj, index) => {
        totalSum = totalSum + Number(sizeObj?.quantity && sizeObj?.quantity)
      })
    }
    if(showSetupChargeInCart && catalogueCustomRows?.[0]?.[0]?.quantity) {
      let quantity = 0;
      if(catalogueCustomRows?.[0]?.length > 0) {
        catalogueCustomRows[0].forEach(row => {
          quantity += Number(row.quantity)
        })
      }
      if(product?.ChargeArray?.Charge?.length > 0) {
        product.ChargeArray.Charge.forEach(charge => {
          if(setupCharges.findIndex(x => x.chargeCode === charge.chargeCode) === -1) {
            const chargeByQuantity = charge?.ChargePriceArray?.ChargePrice?.filter(x => Number(x.xMinQty) <= quantity)
            if(chargeByQuantity.length > 0) {
              const totalSetupChargeUnit = (chargeByQuantity?.[chargeByQuantity.length - 1]?.salePrice ?? 0) * (charge?.matchOrderQty === "1" ? quantity : 1);
              if (totalSetupChargeUnit > 0) {
                setupChargeName = charge?.chargeName ?? 'Setup Charge';
                totalSetupCharge = Number(totalSetupCharge) + totalSetupChargeUnit
                toatlPrice = Number(toatlPrice) + Number(totalSetupCharge)
                setupCharges.push({ totalSetupCharge: totalSetupChargeUnit, setupChargeName, chargeCode: charge.chargeCode })
              }
            }
          }
        })
      }
    }
    let itemsQty = 0
    itemsRows && itemsRows.length > 0 && itemsRows.map((sizeObj, index)=>{
      itemsQty = itemsQty + Number(sizeObj?.quantity ? sizeObj?.quantity : 0)
    })
   if(getSettingStatus('showDecoration')) {
    itemRowArray.decorations &&
    itemRowArray.decorations.map((decoArray, index) => {
      let artwork = null
      if ( artworksFromStateAll?.length ) {
        artwork = artworksFromStateAll.find(
            (artworkArray)=>(artworkArray.id === decoArray.artworkID && artworkArray.artworkLocation === decoArray.location),
        )
        if (!!!artwork && artworksProductState?.length) {
          artwork = artworksProductState.find((artworkArray) =>
              artworkArray?.productToDecorationMapping?.some(
                  (res) => res.locationName === decoArray.location && artworkArray.id === decoArray.artworkID
              )
          );
        }
      }

      let totalAdditionalCharges = 0
      let setupChargeSalePrice = 0;
      let salePrice = 0;
      if (isPriceBreak) {
        let decoChargeArray = getDecoChargesByQuantity([decoArray], totalSum);
        setupChargeSalePrice = Number(decoChargeArray?.[0]?.setupChargeSalePrice || 0);
        salePrice = Number(decoChargeArray?.[0]?.salePrice || 0) * totalSum;
      } else {
        itemsRows.forEach((res, key) => {
          const dtmainArrr = getDecoChargesByQuantity([decoArray], res.quantity);
          salePrice += Number(dtmainArrr?.[0]?.salePrice || 0) * res.quantity;
          if (key === 0) {
            setupChargeSalePrice = Number(dtmainArrr?.[0]?.setupChargeSalePrice || 0);
          }
        });
      }
      const additionalCharges = []
      if(artwork) {
        let totalItem = 0
        itemsRows && itemsRows.length > 0 && itemsRows.map((sizeObj, index)=>{
          totalItem = totalItem + Number(sizeObj?.quantity && sizeObj?.quantity)
        })
        const additionalChargeData = getAdditionalDecorationCharge(artwork, totalItem, decoArray.locationId)
        if( additionalChargeData ) {
          totalAdditionalCharges += additionalChargeData.totalAdditionalCharges;
          additionalCharges.push(...additionalChargeData.additionalCharges);
        }
      }
      // if (decoChargeArray.length > 0){
      if (artwork?.artworkSource === 'aether' && ((getSettingStatus('showDecoration') && customDecorationFee && allowCustomDecoration) || (showSetupChargeInCart && !getSettingStatus('showDecoration')))) {
        if(showSetupChargeInCart) {
          toatlPrice = Number(toatlPrice) + Number(customDecorationSetupFee)
        }
        totalSetupDecoCharges =
          totalSetupDecoCharges +
          Number(customDecorationSetupFee)
        totalDecoCharges =
          totalDecoCharges + (Number(customDecorationPerUnitPrice) * itemsQty)

        } else {
          if(showSetupChargeInCart) {
            additionalCharges.forEach(additionalCharge => {
              if (additionalCharge?.totalSetupCharge) {
                setupCharges.push(additionalCharge)
              }
            })
            totalSetupCharge = totalSetupCharge + totalAdditionalCharges

            toatlPrice =
                Number(toatlPrice) +
                Number(setupChargeSalePrice) +
                Number(totalAdditionalCharges)
          }
          totalSetupDecoCharges =
              Number(totalSetupDecoCharges) +
              Number(setupChargeSalePrice)
          totalDecoCharges = Number(totalDecoCharges) + Number(salePrice)
          /*if (priceTable.length && priceTable.length == 1) {
            toatlPrice = Number(toatlPrice) + Number(totalDecoCharges) * Number(!!(itemsRows?.[0]?.quantity) ? itemsRows?.[0]?.quantity : 0)
            console.log('toatlPrice: ', toatlPrice);
          }*/
        }
      // }
    })
   }

    return (
        <div className="row ml-1">
          {loading && <Loader />}
          <div className="col-md-12 lineitemsrow mb-15 pr-5" key={key}>
            <div className="row">
              <div className="col-md-2 col-sm-12 pr-0">
                {(showColor) && (
                    <>
                      {colorselectbox(itemRowArray, itemRowArray.color, key)}
                    </>
                )}
                {getSettingStatus('showDecoration') &&
                !notCallDecoHtml && locationId !== '' && (
                    <>
                      <div
                          className="col-md-12 w-100 pt-1 decoBlock pl-3"
                          style={{ display: (!!itemRowArray?.decorations && itemRowArray.decorations.length > 0) ? 'flex' : 'none' }}
                      >
                        {/*// TODO: Make a component */}
                        <div className="row mb-2">
                          {itemRowArray?.decorations?.map((decoitem, index) => {
                            let artworkSelected = null
                            if (decoitem.artworkID && artworksFromStateAll?.length) {
                              artworkSelected = artworksFromStateAll.filter(
                                  (artorkArray) => artorkArray.id === decoitem.artworkID,
                              )[0]
                            }
                            let variations = null
                            if (!artworkSelected) {
                              return ''
                            } else {
                              variations = artworkSelected.design.variation.filter(
                                  (variationArray) =>
                                      variationArray.design_variation_unique_id ==
                                      decoitem.variationUniqID,
                              )

                              let getColorIndex = product.LogoInfo.filter(
                                  (val) => val.color === tempSelectedColor,
                              )

                              let getIndex = []
                              if (getColorIndex > 1) {
                                getIndex = product.LogoInfo.filter(
                                    (val) =>
                                        val.color === tempSelectedColor &&
                                        val.location === decoitem.location,
                                )
                              } else {
                                getIndex = getColorIndex
                              }

                              if (variations)
                                return variations.map((vari, indexi) => {
                                  let label = decoTypeList.filter(
                                      (val) => val.code === artworkSelected?.design?.designType,
                                  )

                                  return (
                                      <div
                                          className="decorows2 inlinefexDecorationDiv row"
                                          key={'idx' + indexi}
                                          style={{cursor: 'pointer', width: '100%', marginBottom: '15px'}}
                                          onClick={async (e) => selectDeco(getIndex, itemRowArray, decoitem, key) }
                                      >
                                        <div className="col-md-11 col-11 p-0">
                                          {!!!hideDecorationName && <div className="col-12">
                                            <span className='art-text'>
                                              <strong>{artworkSelected?.identity}</strong>
                                            </span>
                                          </div>}
                                          <div className="col-12 decorows_selartwork">
                                            <img
                                                className="pointer"
                                                style={{
                                                  maxHeight: '30px',
                                                  maxWidth: '90%',
                                                }}
                                                src={
                                                  vari?.itemImageThumbnail?.[0] !== ''
                                                      ? productImageUrl(vari.itemImageThumbnail)
                                                      : productImageUrl(PlaceHolder)
                                                }
                                            />
                                          </div>
                                          {!!!hideLocationName && <div className="col-12 p-0">
                                            <span className='art-text'>
                                              {decoitem.location}
                                            </span>
                                          </div>}
                                        </div>
                                      {(!decoitem?.isRequiredForStore || decoitem?.isRequiredForStore == "0") &&
                                        <div className="col-md-1 col-11 p-0">
                                          <DeleteIcon
                                            className="float-right"
                                            style={{ cursor: 'pointer' }}
                                            onClick={async () => {
                                              await showPopupText(key, itemsRows, index)
                                              if (itemRowArray?.decorations && itemRowArray?.decorations?.length > 0) {
                                                decoitem = itemRowArray.decorations[0];
                                              }
                                            }}
                                          />
                                        </div>
                                      }
                                      </div>
                                  )
                                })
                            }
                          })}
                        </div>
                      </div>
                    </>
                )}
              </div>
              <div className="col-md-1 col-sm-12 pt-2 inventory-label text-right pr-1 p-1">
                {isProductInventoryEnabled &&
                !isSmall &&
                !isMedium && (
                    <>
                      {isProductInventoryEnabled && isColorValid(itemRowArray?.color) && <div className="row m-0">
                        <div className="col pb-1 pt-1  p-0 pr-1">
                        <p>
                          {inventorySettings?.['inventoryLabel'] ? inventorySettings['inventoryLabel'] : 'Available Inventory'}
                        </p>
                        </div>
                      </div>}
                      { showSize &&
                          <div className="row m-0">
                            <div className="col pb-1 p-0 pr-1">
                              <p>{sizeLabel}</p>
                            </div>
                          </div>
                      }
                      {showPrice && (
                        <div className="row m-0">
                          <div className="col pb-1 p-0 pr-1">
                            <p>Price</p>
                          </div>
                        </div>
                      )}
                  {product?.showPriceBreak &&
                    todayDates >= product?.startDate &&
                    todayDates <= product?.endDate &&
                    <div className="row m-0">
                      <div className="col pb-1 p-0 pr-1">
                        <p></p>
                      </div>
                    </div>
                  }
                      <div className="row m-0">
                        <div className="col pb-1 p-0 pr-1">
                          <p>Quantity</p>
                        </div>
                      </div>
                    </>
                )}
              </div>
              <div className='col-md-7 pt-1'>
                {isProductInventoryEnabled && isColorValid(itemRowArray?.color) &&
                (isSmall || isMedium) && (
                    <label className="inventoryLabelportalother">
                      {inventorySettings?.['inventoryLabel']
                          ? inventorySettings['inventoryLabel']
                          : 'Available'}
                    </label>
                )}
                <div data-testid = "size-table-plw" className="row sizediv product-price-tbl mb-1"  style={{overflowX: !isProductInventoryEnabled && priceTable.length === 1 && showSize && priceTable?.[0]?.size == 'OSFA' ? 'unset' :  'auto'}}>
                  {priceTable.map((pricerow, keyP) => {
                    let rQty = ''
                    let rPrice = '0'
                    let discountPrice = 0;
                    let rCost = 0
                    let isActive = true
                    let selRowmain = itemsRows.filter(
                        (row) => row.size === pricerow.size,
                    )
                    let selpriceRowmain = itemsRows.slice(-1)

                    if (pricerow?.priceOptions?.length) {
                      pricerow.priceOptions.map((priceoption) => {
                        // if color is present and showCOlor toggle btn hide from admin side
                        let defaultColor = (showColor === false ? (pricerow?.priceOptions[0]?.ColorArray?.Color?.colorName) : itemRowArray.color);
                        if(!itemRowArray.color || itemRowArray.color === `Select ${colorLabel}`) {
                          defaultColor = pricerow?.priceOptions[0]?.ColorArray?.Color?.colorName;
                        }
                        if (
                            checkPriceOptionsArray(
                                priceoption,
                                defaultColor,
                                pricerow.size,
                            )
                        ) {
                          if (
                              priceoption.active ||
                              priceoption.active === false ||
                              priceoption.active === 'false'
                          ) {
                            if (
                                priceoption.active === false ||
                                priceoption.active === 'false'
                            ) {
                              isActive = false
                            }
                          }

                          if (selRowmain?.length > 0) {
                            rQty = selRowmain[0].quantity
                            rowTotalQty = (
                                Number(rowTotalQty) + Number(selRowmain[0].quantity)
                            ).toString()
                          }
                          toatlQty = Number(toatlQty) + Number(rQty)
                          if (isPriceBreak) {
                            priceoption.partPrice.PartPriceArray.PartPrice.map(
                                (partPrice, k) => {
                                  if (
                                      /*rQty !== '' &&
                                      Number(rQty) > 0 &&*/
                                      Number(partPrice.minQuantity) <= checkTaotalQty()
                                  ) {
                                    rPrice = partPrice.salePrice
                                    rCost = partPrice.price
                                    minIsPriceBreak = partPrice.minQuantity
                                  } else if (k === 0) {
                                    minIsPriceBreak = partPrice.minQuantity
                                    rPrice = partPrice.salePrice
                                    rCost = partPrice.price
                                  }
                                },
                            )

                          } else {
                            priceoption.partPrice.PartPriceArray.PartPrice.map(
                                (partPrice, k) => {
                                  if (
                                      rQty !== '' &&
                                      Number(rQty) > 0 &&
                                      Number(rQty) >= Number(partPrice.minQuantity)
                                  ) {
                                    rPrice = partPrice.salePrice
                                    rCost = partPrice.price
                                  } else if (k === 0) {
                                    rPrice = partPrice.salePrice
                                    rCost = partPrice.price
                                  }
                                },
                            )
                          }
                          let decosalePrice = 0
                          if (addDecoPriceFlag && (Number(rQty) > 0 || (isPriceBreak && Number(totalSum) > 0))) {
                            //addDecoPriceFlag = false;
                           if (artwork_selected?.artworkSource == 'aether' && getSettingStatus("showDecoration") && customDecorationFee && allowCustomDecoration) {
                              itemRowArray && itemRowArray.decorations && itemRowArray.decorations.map((a) => {
                                decosalePrice = decosalePrice + Number(customDecorationPerUnitPrice);
                              });
                           } else {
                            decosalePrice = getDecoPriceByQuantity(
                                itemRowArray?.decorations,
                                isPriceBreak ? totalSum : rQty,
                            )
                           }
                          } else if (addDecoPriceFlag && Number(rQty) === 0) {
                           if (artwork_selected?.artworkSource == 'aether' && getSettingStatus("showDecoration") && customDecorationFee && allowCustomDecoration) {
                              itemRowArray && itemRowArray.decorations && itemRowArray.decorations.map((a) => {
                                  decosalePrice = decosalePrice + Number(customDecorationPerUnitPrice);
                              });
                           } else {
                            decosalePrice = getDecoPriceByQuantity(
                                itemRowArray?.decorations,
                                1,
                            )
                           }
                          }
                          let mainDecorationCost = 0
                          let mainPersonalizationCost = 0
                          let mainDecoCost = 0
                          if (!showDecorationCostInCart && getSettingStatus('showDecoration')) {
                            rPrice = Number(rPrice) + Number(decosalePrice)
                            if (product?.doNotCalculateAtOrder == "1" && isRestrictQty) {
                              mainDecorationCost = Number(decosalePrice) * Number(rQty)
                              mainDecoCost =  Number(decosalePrice)
                            }
                          }

                          if (Number(userGroupDiscount > 0) && Number(rPrice) > 0) {
                            rPrice =
                                Number(rPrice) -
                                (Number(rPrice) * Number(userGroupDiscount)) / 100
                            if (rPrice < 0) {
                              rPrice = 0
                            }
                          }
                          let personalizationCost2 = 0
                          if(getSettingStatus('showPersonalization') && !showPersonalizationCostInCart  && !!itemRowArray.personalization && !!personalizationCost?.price) {
                            personalizationCost2 = (rowTotalQty > 0 ? Number(personalizationCost.price).toFixed(decimalsToShow) : 0)
                            rPrice = Number(rPrice) + Number(personalizationCost2)
                            if (personalizationCost.matchOrderQty == '1' && !getSettingStatus('showPersonalizationCostInCart') && product?.doNotCalculateAtOrder == "1" && isRestrictQty) {
                              mainPersonalizationCost = Number(personalizationCost2) * Number(rQty)
                            }
                          }

                          rWithoutDiscPrice = rPrice

                          if (discountType === '1' && showSell && Number(rPrice) > 0) {
                            if (offerPrice > 0) {
                              discountPrice =  Number(offerPrice)
                              rPrice = Math.max(0, Number(rPrice) - Number(offerPrice)); // if less than zero, then assign zero
                              if(!showSellPrice) {
                                rWithoutDiscPrice = Math.max(0, Number(rWithoutDiscPrice) - Number(offerPrice));
                              }
                            }
                          } else if (discountType === '0' && showSell && Number(rPrice) > 0) {
                            if (offerPrice > 0) {
                              discountPrice =  Number((Number(rPrice) * Number(offerPrice)) / 100)
                              rPrice =
                                  Number(rPrice) -
                                  (Number(rPrice) * Number(offerPrice)) / 100
                              if (rPrice < 0) {
                                rPrice = 0
                              }
                              if(!showSellPrice) {
                                rWithoutDiscPrice = Math.max(0, Number(rWithoutDiscPrice) - (Number(rWithoutDiscPrice) * Number(offerPrice) / 100));
                              }
                            }
                          }
                          if (showDecorationCostInCart && getSettingStatus('showDecoration')) {
                            if (artwork_selected?.artworkSource == 'aether' && customDecorationFee && userType && userType !== USERTYPES.USER && allowCustomDecoration) {
                              toatlPrice =
                                  Number(toatlPrice) +
                                  (Number(customDecorationPerUnitPrice) * Number(rQty))
                            } else {
                              toatlPrice =
                                  Number(toatlPrice) +
                                  Number(decosalePrice) * Number(rQty)
                            }
                          }
                          let calPriceIsPrice = Number(rPrice) * Number(rQty)
                          if (product?.doNotCalculateAtOrder == "1" && Number(rQty) > 0 && product?.isRestrictQty) {
                            calPriceIsPrice = Number(rPrice) + (Number(mainPersonalizationCost) - Number(personalizationCost2)) + (Number(mainDecorationCost) - Number(mainDecoCost))
                          }
                          if (selRowmain.length > 0 && isPriceBreak) {
                            rowTotal = Number(rowTotal) + calPriceIsPrice
                            toatlPrice = Number(toatlPrice) + calPriceIsPrice
                            totalCost = Number(rCost) * Number(rQty)
                          } else {
                            rowTotal = Number(rowTotal) + calPriceIsPrice
                            toatlPrice =Number(toatlPrice) + calPriceIsPrice
                            totalCost = Number(rCost) * Number(rQty)
                          }
                        }
                      })
                    }

                    let color = itemRowArray.color;
                    if (!product.showColorProd && colors_final.length === 1) {
                      color = colors_final[0];
                    }
                    let localPartId;
                    for(const pp of partIds){
                      if(pricerow.size.toLowerCase()?.trim() === pp.size.toLowerCase()?.trim() &&
                      color.toLowerCase()?.trim() === pp.color.toLowerCase()?.trim()){
                        localPartId = pp.partId;
                      }
                    }
                    let inventoryValue = getStock(
                        pricerow.size,
                        color,
                        localPartId
                    )
                    totalStock += Number(inventoryValue);
                    let inventoryFlag = false

                    if (
                        isProductInventoryEnabled &&
                        !IsAllowOutOfStockOrder &&
                        inventoryValue === 0
                    ) {
                      inventoryFlag = true
                    }

                    let setSelectedColor = pricerow.priceOptions.filter(item => item.ColorArray.Color.colorName == itemRowArray.color )
                    if (isActive)
                      return (
                          <div className={`col-lg sizeContainer ${itemRowArray?.color ? ((itemRowArray?.color !== `Select ${colorLabel}`) ? (setSelectedColor[0] ? 'active' : 'deactive') : "") : ""}`} key={keyP}>
                            {isProductInventoryEnabled && isColorValid(itemRowArray?.color) && (
                                <label title={inventorySettings?.['inventoryLabel']
                                ? inventorySettings['inventoryLabel']
                                : 'Available'} className="inventorylabel">
                                  {WithCommas(inventoryValue)}
                                </label>
                            )}
                            <div
                                title={sizeLabel}
                                className={`sizelabelwrap ${inventoryFlag ? 'bglightgray rejectsize' : ''} ${inventoryFlag && showPrice === true ? ' rejectsizeTop' : ''}`}
                                style={{height: (showPrice === false) ?  "56px" : ""}}
                            >
                              {inventoryFlag && showSize && <div className="line"></div>}
                              {
                                showSize &&
                                <b style={{marginLeft: '3px', marginRight: '3px',display: "flex", alignItems: "center", justifyContent: "center"}}>
                                  {' '}
                                  {pricerow.size !== 'OSFA' ? pricerow.size : 'OSFA'}
                                </b>
                              }
                            </div>
                            {(showPrice !== false) && (
                                <>
                                  <label
                                      className={`${showSellPrice ? 'breakable-price' : ''
                                      } bggray`}
                                      title="Price"
                                  >
                                    {
                                      usePointsAsCurrency ? <>{getPointsValue(selpriceRowmain[0]?.price && isPriceBreak && totalSum >= minIsPriceBreak ? (showSellPrice ? rWithoutDiscPrice : rPrice) : rWithoutDiscPrice)}</> : <>
                                        {currency.currencySymbol}
                                        {numberWithCommas2((selpriceRowmain[0]?.price && isPriceBreak && totalSum >= minIsPriceBreak ? (showSellPrice ? rWithoutDiscPrice : rPrice) : rWithoutDiscPrice),
                                            decimalsToShow)}
                                      </>
                                    }
                                  </label>
                                  {showSellPrice && (
                                      <label className="bggray" title="Price">
                                        {
                                          usePointsAsCurrency ? <>{getPointsValue(selpriceRowmain[0]?.price && isPriceBreak && totalSum >= minIsPriceBreak ? rPrice : Math.max(0,Number(rWithoutDiscPrice - discountPrice)))}</> :
                                              <>
                                                {currency.currencySymbol}
                                                {numberWithCommas2((selpriceRowmain[0]?.price && isPriceBreak && totalSum >= minIsPriceBreak ? rPrice : Math.max(0,Number(rWithoutDiscPrice - discountPrice))),
                                                    decimalsToShow)}
                                              </>
                                        }
                                      </label>
                                  )}
                                </>
                            )}

                            <label className="inputQty">
                              <input
                                  type="text"
                                  placeholder="0"
                                  onClick={(e) => changeQty(e, key, pricerow.size, 0, itemRowArray?.color)}
                                  onChange={(e) => changeQty(e, key, pricerow.size, 0, itemRowArray?.color)}
                                  onBlur={(e) => changeQty(e, key, pricerow.size, 0, itemRowArray?.color)}
                                  style={{minWidth: '80px'}}
                                  disabled={
                                    inventoryFlag
                                        ? true
                                        : false
                                  }
                                  value={rQty ? rQty : ''}
                                  name="quantity"
                                  id={`quantity${key}`}
                                  key={`quantity${key}`}
                              />
                              <span className="inputQtyTitle">{`${(!!itemRowArray?.color && itemRowArray?.color !== `Select ${colorLabel}`) && !!showColor ? 'Quantity' : (!!showColor ? `Please select ${colorLabel} first` : 'Quantity')}`}</span>
                            </label>
                          </div>
                      )
                  })}
                </div>
                <div className="row">
                  {isReplace && getSettingStatus('showDecoration') && (
                     <>
                        {itemRowArray.color !== '' &&
                        itemRowArray.color !== 'undefined' &&
                        itemRowArray.color !== undefined && itemRowArray.color !== `Select ${colorLabel}` && (
                            <div
                                  className="col-md-6"
                                  onClick={() => ToggleDivs(key, 'D')}
                                  style={{zIndex: '100'}}
                              >
                            {(getSettingStatus('showPersonalization') || isDrpDeco === true || (!!artWorkCount && artWorkCount.length > 0 && getSettingStatus('showPersonalization') && isDrpDeco === false) || (!!artWorkCount && artWorkCount.length > 1 && !getSettingStatus('showPersonalization') && isDrpDeco === false)) && 
                              <i
                                  className={
                                    getDisplayStyle(key, 'D') === 'none'
                                        ? 'fa fa-chevron-right pr-1'
                                        : 'fa fa-chevron-down pr-1'
                                  }
                                  aria-hidden="true"
                              />
                            }
                              <label className="toggleLabel">
                                {productSettings?.['showDecorationLable']
                                    ? productSettings['showDecorationLable']
                                    : 'Customize This Item'}
                              </label>
                          </div>
                        )}
                      </>
                  )}
                  {getSettingStatus('showPersonalization') && (
                      <div
                          className="col-md-6"
                          onClick={() => {
                            addPersonalization(key)
                            setPersonalizationAdded(true)
                            ToggleDivs(key, 'P')
                          }}
                          style={{zIndex: '100'}}
                      >
                        <i className={getDisplayStyle(key, 'P') === 'none' ? 'fa fa-chevron-right pr-1'
                            : 'fa fa-chevron-down pr-1'} aria-hidden="true"/>
                        <label className="toggleLabel">
                          {!!product?.productPersLabel ? product?.productPersLabel : productSettings?.['showPersonalizationLabel']
                              ? productSettings['showPersonalizationLabel']
                              : 'Personalization'}
                        </label>
                      </div>
                  )}
                  {(isSkuMoq || isMoq) && showMoqNote && (
                    <div className="col-md-6">
                      <p style={{color: 'red', fontSize: '0.85rem'}}>{moqNote}</p>
                    </div>
                  )}
                </div>
              </div>
              <div className={'col-md-2'}>
                <div className="row pt-1 pl-3">
                  <div className="col-6">
                    {itemRowArray.id && (showAddVariation || isDisableProductClone) && catalogueCustomRows?.length > 1 && (
                        <IconButton
                            aria-label="delete"
                            className="pt-2 p-1 removeeffect iconDisplaystyle"
                            title="Delete line"
                            onClick={() => deleteRow(key)}
                        >
                          <DeleteIcon style={{fontSize: 30, marginBottom: '1px'}}/>
                          <span className="iconSpan">Delete</span>
                        </IconButton>
                    )}
                  </div>
                  <div className="col-6">
                    {itemRowArray.id && isDisableProductClone && (
                        <IconButton
                            aria-label="copy"
                            title="Copy line"
                            className="pt-2 p-1 removeeffect iconDisplaystyle"
                            onClick={(e) => {
                              copyRow(key)
                            }}
                        >
                          <img
                              src={Copyrowicon}
                              height="30px"
                              width="30px"
                              style={{
                                marginBottom: '1px',
                                border: '1px solid lightgray',
                                padding: '2px',
                              }}
                          />
                          <span className="iconSpan">Clone</span>
                        </IconButton>
                    )}
                  </div>
                </div>
                <div className="row pt-2 pl-3">
                  <div className="col-6">
                    <div
                        className="rowTotalQty"
                        style={{maxWidth: 'fit-content'}}
                    >
                      <b>Qty:</b>
                      <p className="pt-1 mb-0" style={{wordWrap: "break-word"}}>{rowTotalQty}</p>
                    </div>
                  </div>
                  <div className="col-6 p-0">
                    {(showPrice !== false) && (
                        <div className="rowTotal" style={{textAlign:"center"}}>
                          <b>Total:</b>
                          {
                            usePointsAsCurrency ? getPointsFromValue(rowTotal) :
                                <p className="pt-1 mb-0" style={{wordWrap: "break-word"}}>
                                  {`${currency.currencySymbol}${productSettings?.priceRoundUp ? roundedPrice(Number(rowTotal), decimalsToShow) : numberWithCommas2(Number(rowTotal), decimalsToShow)}`}
                                </p>
                          }

                        </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              {showPrice && rowTotalQty > 0 && (
                <>
                  <div className="offset-md-5 col-md-4 pr-0 mb-1 pt-1">
                    <div data-testid = "charge-table-plw" className="chargeTable2 float-right">
                      <table>
                        <tbody>
                        {showSetupChargeInCart && Number(totalSetupCharge) > 0 && setupCharges?.length > 0 && setupCharges.map(charge => {
                          return(
                          <>
                            <tr>
                              <td className="deco_per_stup_currency2">{charge.setupChargeName}</td>
                              <td>
                                <div className="deco_per_stup2">
                                  {
                                    usePointsAsCurrency ? getPointsFromValue(charge.totalSetupCharge) : <>
                                      <div className="">{currency.currencySymbol}</div>
                                      <div>
                                        {Number(charge.totalSetupCharge).toFixed(decimalsToShow)}
                                      </div>
                                    </>
                                  }
                                </div>
                              </td>
                            </tr>
                          </>
                          )
                        })}
                        {showSetupChargeInCart && Number(totalSetupDecoCharges) > 0 && (
                            <>
                              {' '}
                              <tr>
                                <td className="deco_per_stup_currency2">Setup Decoration Charge</td>
                                <td>
                                  <div className="deco_per_stup2">
                                    {
                                      usePointsAsCurrency ? getPointsFromValue(totalSetupDecoCharges) : <>
                                        <div className="">{currency.currencySymbol}</div>
                                        <div>
                                          {Number(totalSetupDecoCharges).toFixed(decimalsToShow)}
                                        </div>
                                      </>
                                    }
                                  </div>
                                </td>
                              </tr>
                            </>
                        )}
                        {showDecorationCostInCart && totalDecoCharges > 0 && (
                            <tr>
                              <td className="deco_per_stup_currency2" title="Decoration Charge">
                                Decoration Charge
                              </td>
                              <td>
                                <div className="deco_per_stup2">
                                  {
                                    usePointsAsCurrency ? getPointsFromValue(Number(totalDecoCharges)) : <>
                                      <div className="">{currency.currencySymbol}</div>
                                      <div>
                                        {rowTotalQty > 0
                                            ? (
                                                Number(totalDecoCharges)
                                            ).toFixed(decimalsToShow)
                                            : 0}
                                      </div>
                                    </>
                                  }
                                </div>
                              </td>
                            </tr>
                        )}
                        {showSetupChargeInCart && getSettingStatus('showPersonalization') && !!itemRowArray.personalization && personalizationCost.setupPrice > 0  && (
                            <tr>
                              <td className="deco_per_stup_currency2" title={`${ !!product?.productPersLabel ? product?.productPersLabel : productSettings?.['showPersonalizationLabel']
                                          ? productSettings['showPersonalizationLabel']
                                          : 'Personalization' } setup Charge`}>
                                { !!product?.productPersLabel ? product?.productPersLabel : productSettings?.['showPersonalizationLabel']
                                          ? productSettings['showPersonalizationLabel']
                                          : 'Personalization' } setup Charge
                              </td>
                              <td>
                                <div className="deco_per_stup2">
                                  {
                                    usePointsAsCurrency ? getPointsFromValue(personalizationCost.setupPrice) : <>
                                      <div className="">{currency.currencySymbol}</div>
                                      <div>
                                        {rowTotalQty > 0
                                            ?
                                            Number(personalizationCost.setupPrice)
                                                .toFixed(decimalsToShow) : 0}

                                      </div>
                                    </>
                                  }
                                </div>
                              </td>
                            </tr>
                        )}
                        {getSettingStatus('showPersonalization') && showPersonalizationCostInCart && !!itemRowArray.personalization && personalizationCost.price > 0  && (
                            <tr>
                              <td className="deco_per_stup_currency2" title={`${ !!product?.productPersLabel ? product?.productPersLabel : productSettings?.['showPersonalizationLabel']
                                          ? productSettings['showPersonalizationLabel']
                                          : 'Personalization' } Charge`}>
                                { !!product?.productPersLabel ? product?.productPersLabel : productSettings?.['showPersonalizationLabel']
                                          ? productSettings['showPersonalizationLabel']
                                          : 'Personalization' } Charge
                              </td>
                              <td>
                                <div className="deco_per_stup2">
                                  {
                                    usePointsAsCurrency ? getPointsFromValue(Number(personalizationCost.price) * rowTotalQty) : <>
                                      <div className="">{currency.currencySymbol}</div>
                                      <div>
                                        {rowTotalQty > 0
                                            ? personalizationCost.matchOrderQty == 1 ? (
                                                Number(personalizationCost.price) * rowTotalQty
                                            ).toFixed(decimalsToShow) : (
                                                Number(personalizationCost.price)
                                            ).toFixed(decimalsToShow)
                                            : 0}
                                      </div>
                                    </>
                                  }
                                </div>
                              </td>
                            </tr>
                        )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="col-md-3">
                    {!personalizationRequired && (
                      <Button
                          variant="contained"
                          onClick={(e) => {
                            deletePersonalization(key)
                          }}
                          color="primary"
                          className="float-right"
                          title={`Delete ${ !!product?.productPersLabel ? product?.productPersLabel : productSettings?.['showPersonalizationLabel']
                          ? productSettings['showPersonalizationLabel']
                          : 'Personalization' }`}
                          style={{display: getDisplayStyle(key, 'P')}}
                      >
                        Delete { !!product?.productPersLabel ? product?.productPersLabel : productSettings?.['showPersonalizationLabel']
                                          ? productSettings['showPersonalizationLabel']
                                          : 'Personalization' }
                      </Button>
                    )}
                  </div>
                </>
              )}
            </div>
            {isReplace && getDisplayStyle(key, 'D') !== 'none' && (
                <div
                    className="row decoBlock"
                    style={{display: getDisplayStyle(key, 'D')}}
                >
                  <div className="col-md-12">
                    {getSettingStatus('showDecoration') &&
                    getDisplayStyle(key, 'D') !== 'none' &&
                    !notCallDecoHtml && !loadingArtworks &&
                    getDecoHtml(key, itemsRows)}
                    <p className={"ml-3"}>{ loadingArtworks ? 'Loading ...' : ''}</p>
                  </div>
                </div>
            )}
            {personalizationAdded && (
                <div
                    className="col-md-12"
                    style={{display: getDisplayStyle(key, 'P')}}
                >
                  <div
                      className="row ml-2"
                      style={{borderBottom: '1px solid lightgray'}}
                  >
                    <div className="col-md-2">
                      <h4>{ !!product?.productPersLabel ? product?.productPersLabel : productSettings?.['showPersonalizationLabel']
                                          ? productSettings['showPersonalizationLabel']
                                          : 'Personalization' }</h4>
                    </div>
                  </div>
                  <div className="row pl-3 decporow">
                    {!personalizationAdded && (
                        <div className="col-md-12 text-center p-2">
                          <Button
                              variant="contained"
                              onClick={() => {
                                addPersonalization(key)
                                setPersonalizationAdded(true)
                              }}
                          >
                            Add { !!product?.productPersLabel ? product?.productPersLabel : productSettings?.['showPersonalizationLabel']
                                          ? productSettings['showPersonalizationLabel']
                                          : 'Personalization' }
                          </Button>
                        </div>
                    )}
                  </div>
                  {personalizationAdded && (
                      <div className="row pl-3 decporow" style={!!personalizationDropDown && !!requiredLinesPersonalization && !!dropDownValues && dropDownValues.length > 0 && dropDownValues.filter((res) => !!res?.isPersonalizationDropDown).length > 0 ? {overflowX: 'unset'} : {}}>
                        <div className="col-md-12 text-center p-2">
                          {getPesonalizationTable(itemsRows)}
                        </div>
                      </div>
                  )}
                </div>
            )}
          </div>
        </div>
    )
  }

  useEffect(() => {
    /**  include personalizationCost in total price.
     logic is if personalizationCost.matchOrderQty=0 then include personalizationCost in total price else personalizationCost.matchOrderQty=1 then  personalizationCost multiple with qty then total cost inculde in total Price **/
    /*if (personalizationCost.price > 0) {
      if (personalizationCost.matchOrderQty === 0) {
        toatlPrice = Number(toatlPrice) + Number(personalizationCost.price)
      } else if (personalizationCost.matchOrderQty === 1) {
        toatlPrice =
            Number(toatlPrice) * Number(toatlQty) +
            Number(personalizationCost.price)
      }
    }*/
  }, [personalizationCost])

  const deletePersonalization = (key) => {
    // setPersonalizationArray(null)
    let tempData = catalogueCustomRows.filter((rowGroupArray, groupkey) => {
      // if (groupkey === key) {
        rowGroupArray = rowGroupArray.filter((groupArray) => {
          if(groupkey === key) {
            ToggleDivs(key, 'P', 'DeleteRow')
            groupArray.personalization = null
            return groupArray
          }
        })
      // }
      return rowGroupArray
    })

    //delete personalization
    let prdt = tempData.filter(rowGroupArray => {
      let cnt = rowGroupArray.filter(groupArray => {
         if(!!groupArray?.personalization) {
          return groupArray
         }
      });
      return cnt.length > 0
    });
    setCatalogueCustomRows(tempData)
    if(!(prdt.length > 0)) {
      setPersonalizationAdded(false)
      setPersonalizationCost((preVal) => ({
        ...preVal,
        ['matchOrderQty']: 0,
        ['price']: 0,
      ['setupPrice']:0,
      ['chargeId'] : ''
      }))
      updatestate(!statchange)
    }
     totalPriceData()
  }
  const getPesonalizationTable = (itemsRows) => {
    let personalizationArray = []
    catalogueCustomRows &&
    catalogueCustomRows.map((rowArray) => {
      if (rowArray.length > 0) {
        rowArray.map((item) => {
          itemsRows.map((itemRowArray) => {
            if (itemRowArray.id === item.id) {
              item?.personalization &&
              item.personalization.map((personalization) => {
                personalizationArray.push(personalization)
              })
            }
          })
        })
      }
    })
    let rowQtyNo = null
    let rowId = null
    return (
        <Table className="personalizationTabel">
          <thead>
          <tr>
            <td>Item</td>
            <td>{sizeLabel}</td>
            <td>Item {colorLabel}</td>
            <td>Line</td>
            <td>Text</td>
            <td></td>
          </tr>
          </thead>
          <tbody>
          {personalizationArray.map((personalization, key) => {
            let flag = true
            if (Number(personalization.sequance) !== 1) {
              flag = false
            }
            rowQtyNo = personalization.rowQtyNo
            rowId = personalization.rowId
            const objOptions = []
            if(!!personalization?.isDrp) {
              !!personalization?.drpValue && personalization?.drpValue.length > 0 && personalization.drpValue.map(op => {
                objOptions.push({ value: op, label: op })
              })
            }
            return (
                <tr key={key}>
                  <td>{flag && 'Item ' + personalization.rowQtyNo}</td>
                  <td>{flag && personalization.itemSize}</td>
                  <td>{flag && personalization.itemColor}</td>
                  <td>{!!personalization?.lineName ? personalization?.lineName : 'Line ' + personalization.sequance}</td>
                  <td>
                  {
                    !(!!personalization?.isDrp) ? (
                      <input
                        onChange={(e) => {
                          changePersonalizatinArray(e, "displayText", personalization);
                        }}
                        value={personalization.displayText}
                      />
                    ) : (
                      <div className="selectDropDown">
                        <Select
                          noOptionsMessage={() => "No matches for this search"}
                          options={objOptions}
                          styles={colorStyles}
                          components={{
                            DropdownIndicator: () => null,
                            IndicatorSeparator: () => null,
                          }}
                          name="isDrpValuePer"
                          onChange={(e) =>
                            changePersonalizatinArray(e, "displayText", personalization, 1)
                          }
                        />
                      </div>
                    )
                  }
                  </td>
                  {(!(!!personalization?.isDrp) && !(!!personalization?.isEnabled) && !(!!personalization?.requiredLine)) ? (
                  <td>
                    {flag && (
                        <AddIcon
                            onClick={() => {
                              AddPersonalizationRow(personalization)
                            }}
                        />
                    )}
                    {!flag && (
                        <DeleteIcon
                            onClick={() => {
                              DeletePersonalizationRow(personalization, key)
                            }}
                        />
                    )}
                  </td>
                  ) : '' }
                </tr>
            )
          })}
          </tbody>
        </Table>
    )
  }
  const changePersonalizatinArray = (e, type, DataArray, isDrp = 0) => {
    let tempArray = catalogueCustomRows.filter((rowGroup) => {
      if (rowGroup.length > 0) {
        rowGroup.filter((rowArray) => {
          if (rowArray.id === DataArray.rowId) {
            rowArray.personalization = rowArray.personalization.filter(
                (ItemArray) => {
                  if (
                      ItemArray.rowQtyId === DataArray.rowQtyId &&
                      ItemArray.sequance === DataArray.sequance &&
                      ItemArray.rowQtyNo === DataArray.rowQtyNo
                  ) {
                    if (type === 'Font') {
                      ItemArray.font = e.target.value
                    } else if (type === 'Color') {
                      ItemArray.color = e.target.value
                    } else if (type === 'Location') {
                      ItemArray.location = e.target.value
                    } else if (type === 'Note') {
                      ItemArray.notes = e.target.value
                    } else if (type === 'displayText') {
                      ItemArray.displayText = isDrp === 1 ? e.value : e.target.value
                    }
                  }
                  return ItemArray
                },
            )
          }
          return rowArray
        })
      }
      return rowGroup
    })

    //change personaliztion
    setCatalogueCustomRows(tempArray)
    setToggleValue(!toggleValue)
  }
  const AddPersonalizationRow = (personalization) => {
    let newRowData = null
    let tempArray = catalogueCustomRows.filter((rowGroup) => {
      if (rowGroup.length > 0) {
        rowGroup.filter((rowArray) => {
          if (rowArray.id === personalization.rowId) {
            let rowIndex = 0
            rowArray.personalization.map((ItemArray, key) => {
              if (
                  ItemArray.rowQtyId === personalization.rowQtyId &&
                  ItemArray.rowQtyNo === personalization.rowQtyNo
              ) {
                rowIndex = key
                newRowData = {
                  ...ItemArray,
                  color: getPersColor(product, productSettings),
                  location: getPersLocation(product, productSettings),
                  font: getPersFont(product, productSettings),
                  notes: getPersNote(product, productSettings),
                  displayText: '',
                  sequance: (Number(ItemArray.sequance) + 1).toString(),
                }
              }
            })
            let TempPersonalization = []
            rowArray.personalization.map((Row, key) => {
              TempPersonalization.push(Row)
              if (rowIndex === key) {
                TempPersonalization.push(newRowData)
              }
            })
            rowArray.personalization = TempPersonalization
          }
          return rowArray
        })
      }
      return rowGroup
    })

    //add personaliztion row
    setCatalogueCustomRows(tempArray)
    setToggleValue(!toggleValue)
  }
  const DeletePersonalizationRow = (personalization, arrayKey) => {
    let newRowData = null
    let tempArray = catalogueCustomRows.filter((rowGroup) => {
      if (rowGroup.length > 0) {
        rowGroup.filter((rowArray) => {
          if (rowArray.id === personalization.rowId) {
            let TempPersonalization = rowArray.personalization
            TempPersonalization = TempPersonalization.filter((row, key) => {
              if (
                  row.rowQtyNo === personalization.rowQtyNo &&
                  personalization.sequance === row.sequance &&
                  personalization.rowId === row.rowId
              ) {
                return false
              }
              return true
            })
            let seq = null
            TempPersonalization = TempPersonalization.filter((row) => {
              if (
                  seq !== null &&
                  row.sequance !== 1 &&
                  row.rowQtyNo === personalization.rowQtyNo &&
                  personalization.rowId === row.rowId
              ) {
                if (Number(seq) + 1 !== Number(row.sequance)) {
                  row.sequance = Number(seq) + 1
                  seq = row.sequance
                }
              } else {
                seq = row.sequance
              }
              return row
            })
            rowArray.personalization = TempPersonalization
          }
          return rowArray
        })
      }
      return rowGroup
    })

    //delete PERS row
    setCatalogueCustomRows(tempArray)
    setToggleValue(!toggleValue)
  }
  const displayDrop = (parentKey) => {
    setIsDrpDeco(true)
  }
  const getDecoHtml = (parentKey, itemsRows) => {
    const locations = getLocationsCondensed();
    let locID = locations.locID
    let artWorkID = locations.artWorkID
    let productColor = locations.productColor;
    let decoExistLocations = locations.decoExistLocations
    let yFilter = locations.yFilter
    let tempDecorationLocations = decorationLocations.filter(itemX => yFilter.includes(itemX.locationName))
    if(locID && locID !== locationId) {
      setLocationId(locID);
    }
    if (decoExistLocations?.length > 0 && tempDecorationLocations?.length > 0) {
      tempDecorationLocations = tempDecorationLocations.filter((f) => {
        if (decoExistLocations.indexOf(f.locationName) !== -1) {
          if(product.restrictAfterFirstChoice && artworksProductState.length > 0) {
            let numberChoice = 0;
            let selectedArtworks = itemsRows?.[0]?.decorations?.filter((row) => row.artworkID) || []
            numberChoice = selectedArtworks.length === tempDecorationLocations.length 
              ? selectedArtworks.length - 1 
              : selectedArtworks.length;
            let arrayArtworks = artworkLocations.filter(x => x.numberChoice === numberChoice && !x.isEnable) ?? [];
            let artworksToReadFiltered = [];
            let arrayArtworksTemp = []; 
            artworksFromStateAll.forEach(x => {
              let index = arrayArtworks.findIndex(z => z.artworkId === x.id);
              if(index === -1) {
                arrayArtworksTemp.push(x);
              }
            })
            arrayArtworksTemp.forEach(x => {
              let insert = x.productToDecorationMapping.filter(y =>  y.locationName === f.locationName).length;
              if(insert > 0) {
                artworksToReadFiltered.push(x);
              }
            });
            return artworksToReadFiltered.length > 0;
          } else {
            return true;
          }
        } 
        return false
      })
    }
    // FIXME: Advance System does not return the locationId in the ArtworkList (only name)
    const selectedLocationId = autoSelectedLocation ? autoSelectedLocation: locID
    const selectedLocation = tempDecorationLocations.find((location) => location.id === selectedLocationId)
    return (
        <div className="mt-3 mb-3">
          <DecorationSelectbox
              key={parentKey}
              itemsRows={itemsRows?.[0]}
              decoExist={itemsRows?.[0]?.decorations}
              handleCheckbox={handleCheckbox}
              setAddnewartId={setAddnewartId}
              handleCheckboxDefault={handleCheckboxDefault}
              tempDecorationLocations={tempDecorationLocations}
              locID={ locID ? `${locID}-${selectedLocationIndex}`: ""}
              clearDecoSelection={clearDecoSelection}
              parentKey={parentKey}
              artworkListById={artworksFromStateAll}
              decoLocation={product?.customArtworkLocation}
              setAddnewart={setAddnewart}
              allowCustomDecoration={allowCustomDecoration}
              productSettingsRenameLocationNameLabel={productSettings?.renameLocationNameLabel}
              productRenameLocationNameLabel={product?.renameLocationNameLabel}
              productSettingsRenameLocationNamePrompt={productSettings?.renameLocationNamePrompt}
              productRenameLocationNamePrompt={product?.renameLocationNamePrompt}
              productSettingsShowLocationNameLabel={productSettings?.showDecorationLocationLabel}
              productShowLocationNameLabel={product?.showDecorationLocationLabel}
              isSearchArtwork={isSearchArtwork}
              searchTextArtwork={searchTextArtwork}
              searchArtworkName={searchArtworkName}
              allowOneDec={allowOneDec}
              callingFrom = "Condensed"
              artworkSearchPlaceholderText = {product?.artworkSearchPlaceholderText}
              displayDrop = {displayDrop}
              allowToUpCustArtwork = {productSettings?.allowToUpCustArtwork}
              showDecoration={getSettingStatus('showDecoration') && getSettingStatus('showSelectArtwork', true)}
          />
          {getSettingStatus('showDecoration') && getSettingStatus('showSelectArtwork', true) && (locID !== "" || autoSelectedLocation !== '') && (
              <AddDecorationportal
                  setSelectedProductArtwork={setSelectedProductArtwork}
                  selectedProductArtwork={selectedProductArtwork}
                  parentKey={parentKey}
                  getDecoPrice={getDecoPrice}
                  locationID={`${autoSelectedLocation ? autoSelectedLocation: locID}-${selectedLocationIndex}`}
                  artWorkID={artWorkID}
                  setCatalogueCustomRows={setCatalogueCustomRows}
                  catalogueCustomRows={catalogueCustomRows}
                  addDecoration={addDecoration}
                  allowCustomDecoration={allowCustomDecoration}
                  showSelectArtwork={getSettingStatus('showSelectArtwork', true)}
                  setAddnewart={setAddnewart}
                  productColor={productColor}
                  setShowData={setShowData}
                  showData={showData}
                  temp={temp}
                  tempDecorationLocations={tempDecorationLocations}
                  locationName={selectedLocation?.locationName}
                  itemsRows={itemsRows?.[0]}
                  searchTextArtwork={searchTextArtwork}
                  artworkSetting={artworkSetting}
                  storeArtwork={storeArtwork}
                  selectedDecorationsList={itemsRows?.[0]?.decorations}
                  artworkDuplicatesAllowed={productSettings?.artworkDuplicatesAllowed}
                  artworkDuplicatesAllowedPro={product?.artworkDuplicatesAllowed}
                  product={product}
                  decoExist={itemsRows?.[0]?.decorations}
                  allowOneDec={allowOneDec}
                  isAdded={isAdded}
                  setIsAdded={setIsAdded}
                  decorationListOrder = {decorationListOrder}
                  preventAutoAttach = {preventAutoAttach}
                  setSelectedLocationIndex = {setSelectedLocationIndex}
                  setSelectedLocation = {setSelectedLocation}
                  hideDecorationName={hideDecorationName}
              />
          )}
        </div>
    )
  }
  const clearDecoSelection = () => {
    let tempcatalogueCustomRows = catalogueCustomRows.filter(
        (rowGroupArray, groupkey) => {
          rowGroupArray.filter((rowArray) => {
            rowArray.decorations = []
            return rowArray
          })

          return rowGroupArray
        },
    )

    //clear deco collection
    setCatalogueCustomRows(tempcatalogueCustomRows)
  }
  const addDecoration = (variation, parentKey) => {
    let locationArray = decorationLocations?.filter(
        (locationArray) => locationArray.id === selectedProductLocation,
    )
    let tempRows = catalogueCustomRows.filter((rowGroupArray, groupkey) => {
      if (groupkey === parentKey) {
        rowGroupArray.filter((rowArray) => {
          let tempDecoArray = rowArray.decorations ? rowArray.decorations : []
          tempDecoArray.push({
            artworkID: selectedProductArtwork,
            location: locationArray[0].locationName,
            locationId: selectedProductLocation,
            variationUniqID: variation.design_variation_unique_id,
          })
          rowArray.decorations = tempDecoArray
          return rowArray
        })
      }
      return rowGroupArray
    })

    //add deco
    setCatalogueCustomRows(tempRows)
    setSelectedProductLocation('')
    setSelectedProductArtwork('')
  }



  useEffect(()=>{
    if(productStockState !== null) {
      setProductDstInventory(productStockState.dstInventory)
      setProductInventory(productStockState.inventory)
    }
  }, [productStockState])

  const getStock = (sizeDefault, sizcolorDefault, partId = -1) => {
    let inventoryArray = null
    let dstInventoryArray = null

    if (productInventory) {
      inventoryArray = productInventory?.PartInventoryArray?.PartInventory
    }
    if (productDstInventory) {
      dstInventoryArray = productDstInventory
    }

    let stock = 0
    if (
        (product?.poType === 'DropShip' || product?.poType === 'Stock') &&
        (isStockFor === 'supplierStock' || isStockFor === 'both')
    ) {
      inventoryArray &&
      inventoryArray.map((stockArray) => {
        
        let check = false;
         if(partId !== undefined && partId !== null && partId !== -1){
           if(stockArray.partId === partId) check = true
        }
          if(check === false)
          if(sizcolorDefault.trim()?.toLowerCase() === stockArray.partColor.trim()?.toLowerCase() &&
            stockArray.labelSize.trim()?.toLowerCase() === sizeDefault.trim()?.toLowerCase())
              check = true;
          
          if(check === true)
          if (stockArray?.InventoryLocationArray?.InventoryLocation?.length) {
            stockArray.InventoryLocationArray.InventoryLocation.map(
                (inventoryArray) => {
                  if (
                      inventoryArray?.inventoryLocationQuantity?.Quantity?.value
                  ) {
                    stock =
                        Number(stock) +
                        Number(
                            inventoryArray.inventoryLocationQuantity.Quantity.value,
                        )
                  }
                },
            )
          }
      })
    }

    let dstStock = 0
    if (
        product?.poType === 'Stock' &&
        (isStockFor === 'distributorStock' || isStockFor === 'both')
    ) {
      dstInventoryArray &&
      dstInventoryArray.map((dstStockArray) => {
        if (dstStockArray.productId === product.productId) {
          if (
              (sizcolorDefault.replace("No Color", "") === dstStockArray.color.replace("No Color", "")) &&
              dstStockArray.size === sizeDefault
          ) {
            dstStock = Number(dstStockArray.quantity)
          }
        }
      })
    }
    return Number(stock) + Number(dstStock)
  }
  const restrictValidation = () => {
    orderAction(`Quantity restrictions for this product are ${minAllQty.toString()}`, 'error', false)
    addFirstRow()
  }
  const checkOrderRows = () => {
    let flag = true
    if (catalogueCustomRows.length === 0) {
      orderAction('Please add row', 'error', false)
      flag = false
    }
    catalogueCustomRows.map((rowGroup) => {
      if (flag) {
        if (rowGroup.length === 0) {
          orderAction('Please add row', 'error', false)
          flag = false
        } else {
          let checkQty = true
          rowGroup.map((rowArray) => {
            if (flag) {

              if ((rowArray.color === '' || rowArray.color === `Select ${colorLabel}`) && showColor) {
                orderAction(`Please select ${colorLabel}!`, 'error', false)
                flag = false
              } else {
                if (rowArray.size !== '') {
                  if (rowArray.decorations && rowArray.decorations.length > 0) {
                    rowArray.decorations.map((decoArray) => {
                      if (decoArray.locationId !== '') {
                        if (decoArray.artworkID && decoArray.artworkID !== '') {
                          if (
                              decoArray.variationUniqID &&
                              decoArray.variationUniqID !== '' &&
                              decoArray.variationUniqID !== null
                          ) {
                          } else {
                            orderAction(
                                'Please select variation',
                                'error',
                                false,
                            )
                            flag = false
                          }
                        } else {
                          orderAction('Please select artwork', 'error', false)
                          flag = false
                        }
                      } else {
                        if (decoArray.artworkID !== '') {
                          if (
                              decoArray.variationUniqID === '' ||
                              decoArray.variationUniqID === null
                          ) {
                            orderAction(
                                'Please select variation',
                                'error',
                                false,
                            )
                            flag = false
                          }
                        } else {
                          orderAction('Please select artwork', 'error', false)
                          flag = false
                        }
                      }
                    })
                  }
                }
                if(personalizationAdded && rowArray?.personalization) {
                  let blnktest = rowArray.personalization.filter(x => x.displayText === '')
                  if(blnktest.length > 0) {
                    orderAction('You have added personalization to this product, but you haven’t added your text. Please add your text or delete Personalization to add this product to your cart.', 'error', false)
                    flag = false
                  }
                }
                if (Number(rowArray.quantity) > 0) {
                  checkQty = false
                }
              }
            }
          })
          if (checkQty) {
            orderAction('Please enter quantity', 'error', false)
            flag = false
          }
        }
      }
    })
    if(RequiredDecorationForCheckOutPro === true) {
      let tempRow = []
        catalogueCustomRows.forEach(catalogueCustomRow => {
          let tempEachRow = [];
          catalogueCustomRow.forEach(row => {
            if (row.quantity && Number(row.quantity) > 0) {
              tempEachRow.push(row);
            }
          });
        tempRow.push(tempEachRow);
      });
      if(!!tempRow[0][0]?.decorations && !(tempRow[0][0]?.decorations.length > 0)) {
        orderAction('Please choose a decoration to add this product to your cart', 'error', false)
        flag = false
      }
    }
    return flag
  }
  const addtoCart = () => {
    catalogueCustomRows.map((rowGroup) => {
      rowGroup.map((rowArray) => {
        if(!!rowArray?.decorations && rowArray.decorations.filter(res=>res.artworkID).length === 0){
          rowArray.decorations = []
        }
      })
    })
    if (checkOrderRows()) {
      let selectedCartItem = cartItems.filter(
          (cartItem) => cartItem.product && cartItem.product.id === product.id,
      )[0]

      // catalogueCustomRows[0][0].personalizationCost = personalizationCost
      catalogueCustomRows[0][0].rollDecoChargesToProduct = 0
      catalogueCustomRows[0][0].rollDecoAddonChargesToProduct = 0

      if (catalogueCustomRows[0][0]?.decorations?.length) {
        catalogueCustomRows[0][0].rollDecoChargesToProduct = 1
        catalogueCustomRows[0][0].rollDecoAddonChargesToProduct = 1
      }
      let tempRow = []
      let cnt = 0
      let isPersonalization = false
      catalogueCustomRows.map((itemsRowsabs, keya) => {
        itemsRowsabs && itemsRowsabs.length > 0 && itemsRowsabs.map((sizeObj, index) => {
          if (!!sizeObj?.personalization) {
            isPersonalization = true
          }
        })
      })
      catalogueCustomRows.forEach(catalogueCustomRow => {
        let tempEachRow = [];
        catalogueCustomRow.forEach(row => {
          if (row.quantity && Number(row.quantity) > 0) {
            cnt = cnt + 1
            if(cnt === 1 && getSettingStatus('showPersonalization') && isPersonalization === true) {
              row.personalizationCost = personalizationCost
            }
            tempEachRow.push(row);
          }
        });
        tempRow.push(tempEachRow);
      });
      tempRow.forEach(async (item, index) => {
        addItemsRowsTocart(product, [item], index === tempRow.length -1 ? _addToast: null, isPriceBreak, ipV4, userGroupDiscount, index !== tempRow.length - 1);
      })
      goToCart()
      let cartCustomRows = product?.MediaContent.filter(x => x.primary).map(x => {

        return {
          id: uniqIdcounter++,
          size: '',
          quantity: '',
          color: '',
          displayGroup: x.displayGroup,
          group: x.group,
          groups: x.groups,
          hex: x.hex,
          hidden: x.hidden,
          index: x.index,
          logoBlockCount: x.logoBlockCount,
          mediaType: x.mediaType,
          partId: x.partId,
          primary: x.primary,
          productId: x.productId,
          secondary: x.secondary,
          select: x.select,
          sku: x.sku,
          url: x.url,
          price: 0,
          totalPrice: 0,
          cost: 0,
          totalCost: 0,
          product: {sku: x.sku},
          weight: product.weight,
          shipFrom: {
            countryFrom: product.shipFromCountry,
            zipCodeFrom: product.shipFromPostalCode,
          },
        }
      });
      cartCustomRows = cartCustomRows.length > 0 ? [cartCustomRows] : [
        [
          {
            id: uniqIdcounter++,
            size: '',
            quantity: '',
            image: product?.MediaContent?.[0]?.url,
            color: "",
            product: {sku: 0},
            price: 0,
            totalPrice: 0,
          },
        ],
      ]

      setPersonalizationCost((preVal) => ({
        ...preVal,
        ['matchOrderQty']:
        cartCustomRows[0][0]?.personalizationCost?.matchOrderQty,
        ['price']: cartCustomRows[0][0]?.personalizationCost?.price,
        ['setupPrice']: cartCustomRows[0][0]?.personalizationCost?.setupPrice,
        ['chargeId']: cartCustomRows[0][0]?.personalizationCost?.chargeId,
      }))
      setCatalogueCustomRows(cartCustomRows)
      setToggleDivs([])
      updateStateTemp(1)
      document.getElementsByName('quantity').forEach(element => element.placeholder='0');
      // if(priceTable.length === 1) { setaddRowQtyFirst(tempRow[0][0].quantity) }
    }
 
  }
  let colorsTemp = []
  product?.MediaContent &&
  product.MediaContent.map((mediaElement) => {
    if (mediaElement?.color !== '') {
      if (colorsTemp.indexOf(mediaElement.color) === -1) {
        colorsTemp.push(mediaElement.color)
      }
    }
  })
  let showBtn = true
  const goToCart = () => {
    localStorage.setItem('redirectUrl', window.location.pathname)
    if (isDirectToCart) {
      history.push('/cart')
    }
  }

  useEffect(() => {
    if(catalogueCustomRows.length > 0 && statChangeTemp === 1) {
      if(colors_final.length === 1) {
        verifyShowColor()
        changeColor(colors_final[0], '', 0)
      }
      if(priceTable.length === 1 && colors_final.length === 1) {
        verifyShowSize()
        changeQty(addRowQtyFirst, 0 , priceTable[0].size, 1)
      }
      updateStateTemp(0)
    }
  },[statChangeTemp, catalogueCustomRows])

  const totalPriceData = () => {
    let prdt = catalogueCustomRows.filter(rowGroupArray => {
      let cnt = rowGroupArray.filter(groupArray => {
         if(!!groupArray?.personalization) {
          return groupArray
         }
      });
      return cnt.length > 0
    });
    let totalSumqw = 0
    catalogueCustomRows.map((itemsRowsabs, keya) => { itemsRowsabs && itemsRowsabs.length > 0 && itemsRowsabs.map((sizeObj, index) => {
        if(!!sizeObj?.personalization) {
          totalSumqw = totalSumqw + Number(sizeObj?.quantity && sizeObj?.quantity)
        }
      })
    })
    if (personalizationCost?.price !== '' && personalizationCost?.price !== 'undefined' && personalizationCost?.price !== undefined && prdt.length > 0) {
      if (personalizationCost.matchOrderQty == '0') {
        toatlPrice = Number(toatlPrice) + Number(personalizationCost.price)+Number(showSetupChargeInCart ? personalizationCost.setupPrice : 0)
      } else if (personalizationCost.matchOrderQty == '1' && showPersonalizationCostInCart) {
        toatlPrice =
          Number(toatlPrice) +
          Number(personalizationCost.price)* Number(totalSumqw)+ (Number(showSetupChargeInCart ? personalizationCost.setupPrice : 0) * Number(prdt.length))
      }
      else if (personalizationCost.matchOrderQty == '1') {
        toatlPrice = Number(toatlPrice) + (Number(showSetupChargeInCart ? personalizationCost.setupPrice : 0) * Number(prdt.length))
      }
    }
    const totalValue = toatlPrice === 0 || toatlPrice > 0 ? (productSettings?.priceRoundUp ? roundedPrice(Number(toatlPrice), decimalsToShow) : Number(toatlPrice).toFixed(decimalsToShow)) : 0
    return (
        <>
          {(showPrice !== false) && (
              usePointsAsCurrency ? getPointsValue(totalValue) :
                  <b>{`Total: ${currency.currencySymbol}${totalValue}`}</b>
          )}
        </>
    )
  }
  const [tempValue, setTempValue] = useState(0)
  const temp = (parentKey = '', artworkId = '', locaName = "", locationId = null) => {
    let productColor = catalogueCustomRows[parentKey][0].color
    let currentProduct = catalogueCustomRows[parentKey][0]

    if ((parentKey === 0 && artworkId !== '' && locaName === "") || (parentKey !== '' && artworkId !== '' && locaName === "")) {
      if(locaName === "" && !!selectedLocation) {
        const paramLocation = locationId ? locationId : selectedLocation
        let locationNameData = catalogueCustomRows[parentKey][0].decorations.filter(res=>res.locationId === paramLocation)
        locaName = locationNameData?.[0]?.location
        setSelectedLocation(paramLocation)
      }
      let decoData = currentProduct?.decorations?.filter(
          (val) => {
            if(val.artworkID === artworkId && !!locaName && val.location === locaName) {
              return val
            } else if(val.artworkID === artworkId && !!!locaName) {
              return val
            }
          }
      )

      let decoLocation = decoData?.[0]?.location
      setSelectedDecoration(decoData?.[0])

      let getIndex = product.LogoInfo.filter(
          (val) =>
              val.color === productColor &&
              val.location === decoLocation,
      )

      if (getIndex.length > 0) {
        animateScroll.scrollToTop()
        setSelectedImage(getIndex[0]?.image)
        setIsSelectedImageChng(IsSelectedImageChng+1)
      } else {

        let getIndexOfExtra_logo = []
        product.LogoInfo.map(val => {
          if (val.color === productColor) {

            if (val.extra_logo.filter(e => e.location === decoLocation).length > 0) {
              getIndexOfExtra_logo.push(val)
            }
          }
        })
        if (getIndexOfExtra_logo.length) {

          animateScroll.scrollToTop()
          setSelectedImage(getIndexOfExtra_logo[0]?.image)
          setIsSelectedImageChng(IsSelectedImageChng+1)
        } else {
          let getImgColorIndex = product.LogoInfo.filter(
              (val) =>
                  val.color === productColor
          )
          if (getImgColorIndex.length > 0) {
            animateScroll.scrollToTop()
            setSelectedImage(getImgColorIndex[0]?.image)
            setIsSelectedImageChng(IsSelectedImageChng+1)
          }
        }
      }

    } else if (locaName !== "" && locaName !== undefined) {

      let decoData = currentProduct?.decorations?.filter(
        (val) => val.artworkID === artworkId && val.location === locaName,
      )
      setSelectedDecoration(decoData?.[0])
      let getIndex = product.LogoInfo.filter(function (val) {
        return val.color === productColor && val.location === locaName;
      });

      if (getIndex.length > 0) {
        setSelectedImage(getIndex[0].image)
        setIsSelectedImageChng(IsSelectedImageChng+1)
      }

      setTempValue(tempValue + 1)
    } else if(locaName === "" && !!selectedLocation) {
      let locationNameData = catalogueCustomRows?.[parentKey]?.[0]?.decorations.filter(res=>res.locationId === selectedLocation)
      if (locationNameData?.length) {
        let decoData = currentProduct?.decorations?.filter(
          (val) => val.artworkID === artworkId && val.location === locationNameData[0].location,
        )
        setSelectedDecoration(decoData?.[0])

        let getIndex = product.LogoInfo.filter(function (val) {
          return val.color === productColor && val.location === locationNameData?.[0]?.location;
        });

        if (getIndex.length > 0) {
          setSelectedImage(getIndex[0].image)
          setIsSelectedImageChng(IsSelectedImageChng + 1)
        }
      }
      setTempValue(tempValue + 1)
    }
    setNotCallDecoHtml(false)
    ToggleDivs(parentKey, 'D', 'clickOnArtWork')
    setIsNewArtWorkAdded(isNewArtWorkAdded+1)
  }

  return (
      <div className={`portal-condensed container pt-5`}>
        <div className="row">
          <div className="col-md-3 col-sm-12 col-xs-12 pl-0 pr-0">
            <div className="product-large-image-wrapper" id={"sw1"}>
              {swipeimageFlag && (
                  isReplace ? isTrue && <Swiper {...thumbnailSwiperParams}>{swipeimage}</Swiper> :
                      isTrue && <Swiper {...thumbnailSwiperParams}>{swipeimagewebstore}</Swiper>
              )}
            </div>
          </div>
          <div className="col-md-9 col-sm-12 col-xs-12 pl-0 pr-0">
            <div className="d-flex flex-row  justify-content-between">
              <div className="d-flex justify-content-start px-0">
                <h3><strong>{product.productName}</strong></h3>
              </div>
              <div className="d-flex justify-content-end px-0">
                <p><b className="item-number">Item Number</b>:{' '} {itemNumber ? product[itemNumber] : product['productId']}</p>
              </div>
            </div>

            <div className="product-details-content quickview-content">
            {(productSettings?.showCategory === true || productSettingShowDescription === true) && (
                <span className="flex-row  justify-content-between">
                  {productSettings?.showCategory === true && (
                    <div className="d-flex justify-content-end px-0">
                      <span><b>Categories:</b>{' '} {productCategoriesName?.join(', ')} </span>
                    </div>
                  )}
                  {productSettingShowDescription === true && (
                    <div className="d-flex px-0">
                      <b style={{marginRight: '9px'}}>Product Description:</b> <span>{product?.description && parse(product.description)} </span>
                    </div>
                  )}
                </span>
            )}
            <div className="product-details-content quickview-content">
              {(product && priceTables.length > 0 && !!product?.displayPriceGrid) &&
                <PriceTable
                  priceTable={priceTables}
                  product={product}
                  currency={currency}
                  decimalsToShow={decimalsToShow}
                  doNotCalculateAtOrder={product?.doNotCalculateAtOrder}
                  userGroupDiscount={userGroupDiscount}
                  showSellPrice={showSellPrice}
                  showSell={showSell}
                  discountType={discountType}
                  offerPrice={offerPrice}
                  layout='condense'
                />}
            </div>
            </div>
            {/* <div className="row">
              <div className="col-md text-center">
                        <span className="product-description mb-2">
                        {product?.description && ReactHtmlParser(product.description)}
                    </span>
              </div>
            </div> */}
            {product?.showColorSizeDesc === true && (
                <div className="row">
                  <div className="col-md text-center">
                      <span>
                        {product?.colorSizeDesc ? parse(product.colorSizeDesc) : ""}
                      </span>
                  </div>
                </div>
            )}
            {product &&
            catalogueCustomRows?.length > 0 &&
            catalogueCustomRows.map((quoteItemRow, key) => {
              return getRowHTML(quoteItemRow, key)
            })
            }
            <div className="row ml-1">
              <div className="col-md-12 px-0">
                {showAddVariation && 
                  <div className="row">
                    <div className="col-md-12 mb-1 pr-3">
                      <button
                          className="btn add-row float-right"
                          onClick={isRestrictQty ? () => restrictValidation() : () => addFirstRow()}
                      >
                        {!!rowText ? rowText : 'Add Variations'}
                      </button>
                    </div>
                  </div>
                }
                <div
                    className="lineitemsrow mb-25"
                    style={{backgroundColor: 'lightgray'}}
                >
                  <div className="row p-1">
                    <div className={'dispnon col-lg-5 col-sm-2 p-1 lineflex'}></div>
                    <div className={'col-lg-2 lineflex'}>
                      <div className="p-1">
                        <b>{`Total Qty: ${toatlQty}`}</b>
                      </div>
                    </div>
                    <div className={'col-lg-2 col-sm-4 pl-3 lineflex'}>
                      <div className="p-1"> {totalPriceData()}</div>
                    </div>
                    <div className={'col-lg-3 col-sm-4 pl-3 lineflex'}>
                      {showBtn && ((showAddToCartOnProd !== false && getSettingStatus('showAddToCart') !== false) || getSettingStatus('showAddToCart') !== false) && (
                          <div className="cartBtn float-right">
                            {(totalStock > 0 || IsAllowOutOfStockOrder ||  (!selectedProductColor || selectedProductColor === `Select ${colorLabel}`)) ? (
                              <button
                                  onClick={() => {
                                    if (showColor) {
                                      if (!selectedProductColor || selectedProductColor === `Select ${colorLabel}`) {
                                        orderAction(`You must select a ${colorLabel}`, "error");
                                        return;
                                      }
                                    }
                                    if (checkMinQty(prodId, toatlQty, isRestrictQty, minAllQty, isMoq, isSkuMoq, minQty, null, product?.doNotCalculateAtOrder)) {
                                      addtoCart()
                                    }
                                  }}
                              >
                                {' '}
                                Add To Cart{' '}
                              </button>
                            ) : (
                              <button disabled>Out of Stock</button>
                            )}
                          </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {showAddnewart && (
            <AddnewArtDialogue
                productId={productId}
                open={showAddnewart}
                show={showAddnewart}
                showAddnewartId={showAddnewartId}
                title="Add New Artwork"
                onHide={() => handleCancel()}
                handleSubmit={handleAddnewArt}
                cancel={() => handleCancel()}
                catalogueItem={product}
            />
        )
        }

        {showDecoPopup && (
          <SharedModal 
            indexPopup={indexPopup}
            mainArrayKeyPopup={mainArrayKeyPopup}
            itemsRowsPopup={itemsRowsPopup}
            popupText={popupText}
            removeDeco={removeDeco}
            setShowDecoPopup={setShowDecoPopup}
            showDecoPopup={showDecoPopup}
          />
        )}
      </div>
  )
}

PortalImageCondensed.propTypes = {
  cartItems: PropTypes.array,
  compareItems: PropTypes.array,
  currency: PropTypes.object,
  galleryType: PropTypes.string,
  product: PropTypes.object,
  spaceBottomClass: PropTypes.string,
  spaceTopClass: PropTypes.string,
  wishlistItems: PropTypes.array,
}

const mapStateToProps = (state) => {
  return {
    currency: state.currencyData,
    cartItems: state.cartData.cartItems,
    wishlistItems: state.wishlistData,
    artworkList: state.productData.artworkList,
    artworkListById: state.productData.artworkListById,
    compareItems: state.compareData,
    decorationLocations: state.productData.decorationLocations,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    addItemsRowsTocart: (item, rows, _addToast, isPriceBreak, ipV4, userGroupDiscount, holdServerUpdate) => {
      dispatch(addItemsRowsTocart(item, rows, _addToast, isPriceBreak, ipV4, userGroupDiscount, holdServerUpdate))
    },
    fetchDecorationLocations: (data) => dispatch(fetchDecorationLocations(data)),
    fetchProductStock: (data) => dispatch(fetchProductStock(data)),
    fetchArtworksById: (data) => dispatch(fetchArtworksById(data)),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(PortalImageCondensed);
