import { CostCenter, SearchCostCenterRequest } from '../../models/CostCenter'
import { aetherApi } from './aetherApi'

const costCenterApi = aetherApi.injectEndpoints({
  endpoints: (builder) => ({
    searchCostCenters: builder.query<CostCenter[], SearchCostCenterRequest>({
      query: (params) => ({
        url: `api/v1/cost-centers/basic/search`,
        params
      }),
    }),
  }),
})

export const { useSearchCostCentersQuery, useLazySearchCostCentersQuery } = costCenterApi
