import React, { useMemo } from 'react'
import { Box, Card, Divider, Stack, Typography } from '@mui/material'
import { useSelector } from 'react-redux'
import { useAppSelector } from '../../redux/hooks'
import {
  selectCurrency,
  selectDebug,
  selectItems,
  selectLockChanges,
  selectOrderPayments,
  selectOrderTotals,
  selectParams,
  selectPaymentRequests,
  selectRemainingBalanceDue,
  selectStatus,
  selectWorkingConvenienceFee,
} from '../../redux/selectors/checkoutSelectors'
import { ItemListItem } from './ItemListItem'
import { CheckoutStatus } from '../../models/CheckoutSession'
import { PAYMENT_NAMES } from '../../models/PaymentMethodType'
import Decimal from 'decimal.js'
import { OrderPayment } from '../../models/Payment'
import { AetherItemType } from '../../models/OrderItem'
import CouponRow from './CouponRow'
import useCurrencyDisplay from './useCurrencyDisplay'
import { grey } from '@mui/material/colors'

export default function OrderSummaryCard() {
  const debug = useAppSelector(selectDebug)
  const params = useAppSelector(selectParams)
  const status = useAppSelector((state) => selectStatus(state, params))
  const items = useAppSelector((state) => selectItems(state, params))
  const lockChanges = useAppSelector((state) =>
    selectLockChanges(state, params),
  )
  const paymentRequests = useAppSelector(selectPaymentRequests)
  const orderPayments = useAppSelector((state) =>
    selectOrderPayments(state, params),
  )
  const remainingBalanceDue = useAppSelector((state) =>
    selectRemainingBalanceDue(state, params, null),
  )
  const { format } = useCurrencyDisplay()
  const {
    subtotal,
    shipping,
    tax,
    total,
    convenienceFee: orderConvenienceFee,
    discount,
  } = useAppSelector((state) => selectOrderTotals(state, params))

  const { fee: workingConvenienceFee, tax: convenienceFeeTax } = useAppSelector(
    selectWorkingConvenienceFee,
  )

  const convenienceFee = useMemo(
    () => workingConvenienceFee.plus(orderConvenienceFee),
    [workingConvenienceFee, orderConvenienceFee],
  )

  const orderCreated = useMemo(
    () =>
      status === CheckoutStatus.PENDING_PAYMENT ||
      status === CheckoutStatus.PENDING_SUBMISSION,
    [status],
  )

  const displayPayments: OrderPayment[] = [...orderPayments, ...paymentRequests]

  return (
    <Card variant="outlined" data-testid="itemcard">
      <Stack divider={<Divider flexItem />}>
        <>
          <Typography variant="h5" align="left" mt={2} ml={2} gutterBottom>
            Order Summary
          </Typography>
          {items
            ?.filter(
              (i) =>
                (i.aetherData.aetherItemType === AetherItemType.PRODUCT ||
                  debug) &&
                !i.aetherData.isKitChildProduct,
            )
            ?.map((item, idx) => (
              <React.Fragment key={idx}>
                {!item.aetherData.isKitProduct && (
                  <ItemListItem
                    key={idx}
                    item={item}
                    data-testid={'listItem' + idx}
                  />
                )}
                {item.aetherData.isKitProduct && (
                  <Card sx={{ backgroundColor: grey[50], m: 1 }}>
                    <ItemListItem
                      key={idx}
                      item={item}
                      data-testid={'listItem' + idx}
                    />
                    {items
                      .filter(
                        (i) =>
                          i.aetherData.isKitChildProduct &&
                          item.aetherData.kitId === i.aetherData.kitId,
                      )
                      .map((childItem, childIdx) => (
                        <ItemListItem
                          key={childIdx}
                          item={childItem}
                          data-testid={'listItem' + childIdx}
                        />
                      ))}
                  </Card>
                )}
              </React.Fragment>
            ))}
        </>
        <Stack direction={'column'} p={2}>
          <Stack direction={'row'} justifyContent={'space-between'}>
            <Typography variant="body1">Shipping</Typography>
            <Typography variant="body1">
              {orderCreated || shipping > 0 ? format(shipping) : '-'}
            </Typography>
          </Stack>
          {convenienceFee.greaterThan(0) && (
            <Stack direction={'row'} justifyContent={'space-between'}>
              <Typography variant="body1">Convenience Fee</Typography>
              <Typography variant="body1">
                {orderCreated && convenienceFee ? format(convenienceFee) : '-'}
              </Typography>
            </Stack>
          )}
          {discount > 0 && (
            <Stack direction={'row'} justifyContent={'space-between'}>
              <Typography variant="body1">Discount</Typography>
              <Typography variant="body1">-{format(discount)}</Typography>
            </Stack>
          )}
          <Stack direction={'row'} justifyContent={'space-between'}>
            <Typography variant="body1">Subtotal</Typography>
            <Typography variant="body1">
              {format(workingConvenienceFee.plus(subtotal))}
            </Typography>
          </Stack>
          <Stack direction={'row'} justifyContent={'space-between'}>
            <Typography variant="body1">Tax</Typography>
            <Typography variant="body1">
              {orderCreated || tax > 0
                ? format(convenienceFeeTax.plus(tax))
                : '-'}
            </Typography>
          </Stack>
          <Stack direction={'row'} justifyContent={'space-between'} mt={1}>
            <Typography variant="h6">Total</Typography>
            <Typography variant="h6">
              {format(
                new Decimal(total)
                  .plus(workingConvenienceFee)
                  .plus(convenienceFeeTax),
              )}
            </Typography>
          </Stack>
        </Stack>
        {!lockChanges && <CouponRow />}
        {displayPayments.length > 0 && (
          <>
            <Stack direction={'column'} p={2}>
              {displayPayments.map((request, idx) => (
                <Stack
                  key={idx}
                  direction={'row'}
                  justifyContent={'space-between'}
                >
                  <Typography variant="body1">
                    {PAYMENT_NAMES[request.methodType]}
                  </Typography>
                  <Typography variant="body1">
                    - {format(request.amount)}
                  </Typography>
                </Stack>
              ))}
            </Stack>
          </>
        )}
        {orderCreated && (
          <>
            <Stack direction={'row'} justifyContent={'space-between'} p={2}>
              <Typography variant="body1">Balance Remaining</Typography>
              <Typography variant="body1">
                {format(remainingBalanceDue)}
              </Typography>
            </Stack>
          </>
        )}
      </Stack>
    </Card>
  )
}
