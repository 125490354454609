import React from 'react';
import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Grid2,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material';
import { useAppSelector } from '../../redux/hooks';
import {
  selectConfirmationHtml,
  selectContactInfo,
  selectCurrency,
  selectOrder,
  selectOrderNo,
  selectParams,
  selectTransactionIds,
} from '../../redux/selectors/checkoutSelectors';
import parse from 'html-react-parser';
import { useGetConfirmationSettingsQuery } from '../../redux/api/checkoutApi';
import { FulfillmentMethodType } from '../../models/FulfillmentMethod';
import { getAddressString, getEtaString } from '../../helpers/checkout';
import { AetherItemType } from '../../models/OrderItem';
import { ItemListItem } from './ItemListItem';
import { PAYMENT_NAMES } from '../../models/PaymentMethodType';
import { useHistory } from 'react-router-dom';
import { OrderHoldStatus } from '../../models/Order';
import useCurrencyDisplay from './useCurrencyDisplay';

export default function ConfirmationCard() {
  const params = useAppSelector(selectParams);
  const orderNo = useAppSelector((state) => selectOrderNo(state, params));
  const contactInfo = useAppSelector((state) => selectContactInfo(state, params));
  const transactionIds = useAppSelector((state) => selectTransactionIds(state, params));
  const order = useAppSelector((state) => selectOrder(state, params));
  const confirmationText = useAppSelector((state) => selectConfirmationHtml(state, params));

  const history = useHistory();

  const { format } = useCurrencyDisplay();

  const { data: confirmationSettings, isLoading: loadingSettings } =
    useGetConfirmationSettingsQuery();

  const handleBackToHome = () => {
    history.push('/');
  };

  const fulfillment = order?.aetherData.fulfillments?.[0];

  const fProcessWord =
    fulfillment?.method?.type === FulfillmentMethodType.PICKUP ? 'Pickup' : 'Shipping';
  const fCompleteWord =
    fulfillment?.method?.type === FulfillmentMethodType.PICKUP ? 'Pickup' : 'Delivery';

  return (
    <Stack spacing={2}>
      {loadingSettings && <Skeleton variant="rounded" height={200} />}
      {!loadingSettings && (
        <>
          <Typography variant="h3" mb={4}>
            {confirmationSettings?.headerText}
          </Typography>
          {confirmationSettings?.showOrderNumber && (
            <ConfirmationCardRow label="Order Number" value={orderNo} big />
          )}
          {confirmationSettings?.showTransactionId && (
            <ConfirmationCardRow
              label="Transaction ID"
              value={transactionIds.join(', ')}
              testId="transaction-id"
              big
            />
          )}
          {confirmationSettings?.showPaymentMethodHtml && confirmationText && (
            <Box mt={2} mb={2}>
              <Typography variant="h6">{confirmationText && parse(confirmationText)}</Typography>
            </Box>
          )}
          {confirmationSettings?.html && (
            <Box mt={2} mb={2}>
              <Typography variant="h6">
                {confirmationSettings?.html && parse(confirmationSettings.html)}
              </Typography>
            </Box>
          )}
          {confirmationSettings?.showOrderApprovalStatus && (
            <>
              {order?.aetherData?.holdStatus === OrderHoldStatus.HOLD &&
                confirmationSettings?.holdApprovalStatusText && (
                  <Alert severity="warning">{confirmationSettings?.holdApprovalStatusText}</Alert>
                )}
              {order?.aetherData?.holdStatus === OrderHoldStatus.NO_HOLD &&
                confirmationSettings?.noHoldApprovalStatusText && (
                  <Alert severity="success">{confirmationSettings?.noHoldApprovalStatusText}</Alert>
                )}
            </>
          )}
          {confirmationSettings?.showOrderDetails && (
            <Card>
              <CardContent>
                <Stack spacing={2}>
                  <Typography variant="h5" gutterBottom>
                    Order Details
                  </Typography>
                  <ConfirmationCardRow
                    label="Name"
                    value={`${contactInfo.firstName} ${contactInfo.lastName}`}
                  />
                  <ConfirmationCardRow label="Email" value={contactInfo.email} />
                  {fulfillment && (
                    <>
                      {fulfillment.method?.name && (
                        <ConfirmationCardRow
                          label={`${fProcessWord} Method`}
                          value={fulfillment.method.name}
                        />
                      )}
                      {confirmationSettings.showFulfillmentEstimate && (
                        <ConfirmationCardRow
                          label={`Estimated ${fCompleteWord} Date`}
                          value={getEtaString(fulfillment)}
                        />
                      )}
                      {fulfillment.address && (
                        <ConfirmationCardRow
                          label={`${fProcessWord} Address`}
                          value={getAddressString(fulfillment.address)}
                        />
                      )}
                      {fulfillment.method?.instructions && (
                        <ConfirmationCardRow
                          label={`${fProcessWord} Instructions`}
                          value={fulfillment.method.instructions}
                        />
                      )}
                    </>
                  )}
                </Stack>
              </CardContent>
            </Card>
          )}
        </>
      )}
      {confirmationSettings?.showOrderItems && (
        <Card>
          <CardContent>
            <Stack spacing={2}>
              <Typography variant="h5" gutterBottom>
                Items
              </Typography>
              <Grid2 container>
                <Grid2 size={12}>
                  {order?.items
                    ?.filter((i) => i.aetherData.aetherItemType === AetherItemType.PRODUCT)
                    ?.map((item, idx) => [
                      <ItemListItem key={idx} big item={item} data-testid={'listItem' + idx} />,
                    ])}
                </Grid2>
                <Grid2 size={12} m={2}>
                  <Divider />
                </Grid2>
                <Grid2 offset={8} size={4}>
                  <Stack pr={2}>
                    {(order?.aetherData?.displayShipping ?? 0) > 0 && (
                      <ConfirmationCardRow
                        label="Shipping"
                        right
                        value={format(order?.aetherData?.displayShipping ?? 0)}
                        testId="shipping-value"
                      />
                    )}
                    {(order?.aetherData?.displayConvenienceFee ?? 0) > 0 && (
                      <ConfirmationCardRow
                        label="Convenience Fee"
                        right
                        value={format(order?.aetherData?.displayConvenienceFee ?? 0)}
                        testId="convenience-fee-value"
                      />
                    )}
                    {(order?.aetherData?.discount ?? 0) > 0 && (
                      <ConfirmationCardRow
                        label="Discount"
                        right
                        value={format(-(order?.aetherData?.discount ?? 0))}
                        testId="discount-value"
                      />
                    )}
                    <ConfirmationCardRow
                      label="Subtotal"
                      right
                      value={format(order?.aetherData?.displaySubtotal ?? 0)}
                      testId="subtotal-value"
                    />
                    <ConfirmationCardRow
                      label="Tax"
                      right
                      value={format(order?.aetherData?.displayTax ?? 0)}
                      testId="tax-value"
                    />
                    <ConfirmationCardRow
                      label="Total"
                      right
                      value={format(order?.aetherData?.displayTotal ?? 0)}
                      testId="total-value"
                    />
                  </Stack>
                </Grid2>
              </Grid2>
            </Stack>
          </CardContent>
        </Card>
      )}
      {confirmationSettings?.showPayments && (order?.aetherData?.payments?.length ?? 0) > 0 ? (
        <Card>
          <CardContent>
            <Stack>
              <Typography variant="h5" gutterBottom>
                Payments
              </Typography>
              {order?.aetherData.payments?.map((payment, idx) => (
                <ConfirmationCardRow
                  key={idx}
                  label={`${PAYMENT_NAMES[payment.methodType]}`}
                  value={format(payment.amount)}
                  testId={`payment-${idx}-value`}
                />
              ))}
            </Stack>
          </CardContent>
        </Card>
      ) : undefined}
      <Button variant={'outlined'} onClick={handleBackToHome}>
        Back to home page
      </Button>
    </Stack>
  );
}

function ConfirmationCardRow({
  label,
  value,
  right,
  big,
  testId,
}: {
  label: string;
  value?: string;
  right?: boolean;
  big?: boolean;
  testId?: string;
}) {
  if (value) {
    return (
      <Grid2 container>
        <Grid2 size={4}>
          <Typography variant={big ? 'h6' : 'body1'}>{label}</Typography>
        </Grid2>
        <Grid2 size={8}>
          <Typography
            variant={big ? 'h6' : 'body1'}
            textAlign={right ? 'right' : undefined}
            data-testid={testId}
          >
            {value}
          </Typography>
        </Grid2>
      </Grid2>
    );
  }
  return <></>;
}
