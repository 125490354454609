import { Order } from './Order'
import { OrderItem } from './OrderItem'

export enum CheckoutStep {
  CUSTOMER_DETAILS = 'CustomerDetails',
  FULFILLMENT = 'Fulfillment',
  PAYMENT = 'Payment',
}

export enum CheckoutStatus {
  PENDING_DETAILS = 'PendingDetails',
  PENDING_FULFILLMENT_METHOD = 'PendingFulfillmentMethod',
  PENDING_PAYMENT = 'PendingPayment',
  PENDING_SUBMISSION = 'PendingSubmission',
  COMPLETED = 'Completed',
}

export interface CheckoutSession {
  _id: string
  userId: string
  cartId: string
  status: CheckoutStatus
  order: Order,
  confirmationHtml?: string,
  skipCustomerDetails?: boolean
}

export enum RestrictAddressMode {
  NONE = 'none',
  SHIPPING_LOCATIONS = 'shippingLocations',
  SPECIFIC_SHIPPING_LOCATION = 'specificShippingLocation'
}

export interface CheckoutSettings {
  fulfillmentRenameEnabled?: boolean
  fulfillmentRenameText?: string
  showInHandsDateField?: boolean
  ihdRenameEnabled?: boolean
  ihdRenameText?: string
  ihdRequired?: boolean
  showFulfillmentEstimate?: boolean
  instructionsEnabled?: boolean;
  instructionsText?: string;
  deliveryInstructionsEnabled?: boolean;
  deliveryInstructionsText?: string;
  paymentInstructionsEnabled?: boolean;
  paymentInstructionsText?: string;
  orderNoteEnabled?: boolean;
  isFulfillmentPhoneRequired?: boolean;
  isFulfillmentCompanyRequired?: boolean;
  isFulfillmentCompanyRenameEnabled?: boolean;
  fulfillmentCompanyRenameText?: string;
  isBillingPhoneRequired?: boolean;
  isBillingCompanyRequired?: boolean;
  isBillingCompanyRenameEnabled?: boolean;
  billingCompanyRenameText?: string;
  restrictShippingAddressMode?: RestrictAddressMode;
  restrictShippingAddressId?: string;
  restrictBillingAddressMode?: RestrictAddressMode;
  restrictBillingAddressId?: string;
  isBillingAddressHidden?: boolean
  isFulfillmentNameLocked?: boolean;
  isFulfillmentEmailLocked?: boolean;
  isBillingNameLocked?: boolean;
  isBillingEmailLocked?: boolean;
  isAddressCarryoverEnabled?: boolean;
  isShippingEstimatorEnabled?: boolean;
  costCenterEnabled?: boolean;
  costCenterSettings?: CostCenterSettings;
}

export interface ConfirmationPageSettings {
  headerText: string;
  showOrderNumber: boolean;
  showTransactionId: boolean;
  showOrderDetails: boolean;
  showOrderItems: boolean;
  html: string;
  showFulfillmentEstimate: boolean;
  showOrderApprovalStatus: boolean;
  holdApprovalStatusText: string;
  noHoldApprovalStatusText: string;
  showPaymentMethodHtml: boolean;
  showPayments: boolean;
}

export interface CostCenterSettings {
  primaryEnabled: boolean;
  secondaryEnabled: boolean;
  primaryRequired: boolean;
  secondaryRequired: boolean;
  primaryRenameEnabled: boolean;
  secondaryRenameEnabled: boolean;
  primaryRenameText: string;
  secondaryRenameText: string;
  customPrimaryEnabled: boolean;
  customSecondaryEnabled: boolean;
  showAllPrimaryEnabled: boolean;
  showAllSecondaryEnabled: boolean;
}

export interface CostCenterSettings {
  sectionHeaderText: string;
  primaryEnabled: boolean;
  secondaryEnabled: boolean;
  primaryLabelText: string;
  secondaryLabelText: string;
  customPrimaryEnabled: boolean;
  customSecondaryEnabled: boolean;
  showAllPrimaryEnabled: boolean;
  showAllSecondaryEnabled: boolean;
}
